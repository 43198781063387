import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBoxOpen, faChartPie, faCog, faFileAlt, faHandHoldingUsd, faSignOutAlt, faTable, faTimes, faCalendarAlt, faMapPin, faInbox, faRocket } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { RoutesPage } from "../routes";
import ReactHero from "../assets/img/pages/logo.jpeg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import logo from "../assets/img/pages/logo.jpeg";

const Sidebar = ({ role, onToggleMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [show, setShow] = useState(true);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);
  const handleToggleMenu = () => {
    setShow(!show);
    if (onToggleMenu) onToggleMenu(!show);
  };

  const logout = () => {
    localStorage.clear();
    navigate(RoutesPage.Signin.path);
  };

  const CollapsableNavItem = ({ eventKey, title, icon, children = null }) => {
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = ({ title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" }) => {
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      {role === 'admin' && (
        <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4" style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
          <Navbar.Brand className="me-lg-5" as={Link} to={RoutesPage.home.path}>
            <Image src={ReactHero} className="navbar-brand-light" style={{ height: '57px' }} />
          </Navbar.Brand>
          <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={handleToggleMenu}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </Navbar>
      )}
      <CSSTransition in={show} timeout={300} classNames="sidebar-transition" unmountOnExit>
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button as={Link} variant="secondary" size="xs" to={RoutesPage.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <Image src={logo} className="card-img-top rounded-circle border-white text-center" style={{ width: '100px', margin: 'auto', marginBottom: '4%' }} />
              {role === 'admin' && (
                <NavItem title="Home" link={RoutesPage.home.path} icon={faChartPie} />
              )}
              
              <Dropdown.Divider className="my-3 border-indigo" />
              {role === 'admin' && (
                <>
                  <CollapsableNavItem eventKey="params/" title="Formularios" icon={faBoxOpen}>
                    <NavItem title="Informe técnico" link={RoutesPage.Fitting.path} />
                  </CollapsableNavItem>
                  <CollapsableNavItem eventKey="components1/" title="Parametros Gen." icon={faBoxOpen}>
                    <NavItem title="Alcances" link={RoutesPage.Alcances.path} />
                    <NavItem title="Clubs de golf" link={RoutesPage.Clubs.path} />
                    <NavItem title="Grips Medidas" link={RoutesPage.GripsMedidas.path} />
                    <NavItem title="Grips Refrencias" link={RoutesPage.GripsReferencia.path} />
                    <NavItem title="Handicaps" link={RoutesPage.Handicaps.path} />
                    <NavItem title="Pulgadas" link={RoutesPage.Pulgadas.path} />
                    <NavItem title="Long vara" link={RoutesPage.LongVara.path} />
                    <NavItem title="Flexes" link={RoutesPage.Flexes.path} />
                    <NavItem title="Tipo palos" link={RoutesPage.TipoPalo.path} />
                    <NavItem title="Fabricas Varas" link={RoutesPage.FabricaVara.path} />
                    <NavItem title="Marcas de palos de golf" link={RoutesPage.Marcas.path} />
                    <NavItem title="Bolas" link={RoutesPage.Bolas.path} />
                  </CollapsableNavItem>
                  <CollapsableNavItem eventKey="components2/" title="Parametros Especificos" icon={faBoxOpen}>
                    <NavItem title="MDV Palos Cortos" link="/mdv-palos-cortos" />
                    <NavItem title="MDV Palos largos" link={RoutesPage.ModeloDriver.path} />
                    <NavItem title="MDC Palos Largos" link={RoutesPage.MdcPalosLargos.path} />
                    <NavItem title="MDC Wedges" link={RoutesPage.Wedges.path} />
                    <NavItem title="MDC Hierros" link={RoutesPage.MdcHierros.path} />
                    <NavItem title="MDC Putts" link={RoutesPage.MdcPutts.path} />
                    <NavItem title="Loft Hierros" link={RoutesPage.LoftHierros.path} />
                    <NavItem title="Loft Driver" link={RoutesPage.LoftDriver.path} />
                    <NavItem title="Tipo cabezas" link={RoutesPage.TipoCabeza.path} />
                    <NavItem title="MDC Iron Driving" link={RoutesPage.MdcIronDriving.path} />
                  </CollapsableNavItem>
                </>
              )}
              {role === 'user' && (
                <>
                  <NavItem title="Mi recomendación" link={RoutesPage.client.path} icon={faHandHoldingUsd} />
                  <NavItem title="Mis Fitting" link={RoutesPage.FittingClient.path} icon={faHandHoldingUsd} />
                </>
              )}
              <Dropdown.Divider className="my-3 border-indigo" />
              <Button
                onClick={logout}
                className="d-flex align-items-center"
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  borderColor: "transparent",
                  boxShadow: "none"
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                Cerrar sesión
              </Button>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
export default Sidebar;
