import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios'
export const AutoComplete = ({handleClick, props}) => {
    let data = []
    // const url = props.url
    function clients() {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/tipo-palos`)
        .then((response) => {
          data = response.data
        })
        .catch((err) => {
          console.log(err)
        })
    }

    clients()

    const[clientes, setClientes]= useState(data);
    const[value, setValue] = useState("");
    const[clienteSeleccionado, setClienteSeleccionado]= useState({});

    const onSuggestionsFetchRequested=({value})=>{
      setClientes(filtrarCliente(value));
    }

    const filtrarCliente=(value)=>{
      const inputValue=value.trim().toLowerCase();
      const inputLength=inputValue.length;
    
      var filtrado=data.filter((cliente)=>{
        var textoCompleto=cliente.name;
        if(textoCompleto.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(inputValue)){
          return cliente;
        }
      });
    
      return inputLength===0 ? [] : filtrado;
    }

    const onSuggestionsClearRequested = () =>{
      setClientes([]);
    }

    const getSuggestionValue=(clientes)=>{
      return `${clientes.name}`;
    }

    
    const renderSuggestion=(suggestion)=>(
      <div className='sugerencia' onClick={()=>seleccionarCliente(suggestion)}>
        {`${suggestion.name}`}
      </div>
    );

    const seleccionarCliente=(cliente)=>{
      setClienteSeleccionado(cliente);
    }

    const onChange=(e, {newValue})=>{
      setValue(newValue);
    }

    const inputProps={
      placeholder:"Cliente existente",
      className:"form-control",
      value,
      onChange
    };

    const eventEnter=(e)=>{
        if(e.key == "Enter"){
            var split = e.target.value.split('-');
            var cliente ={
                name: split[0].trim(),
            };
            seleccionarCliente(cliente);
        }
    }
    
    return (
        <>
            <Autosuggest 
                suggestions={clientes}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={eventEnter}
            />
            {/* <br /> */}
            <button className='btn btn-primary mb-5 d-none' onClick={handleClick(clienteSeleccionado)}>Seleccionar</button>
        </>
    )  
}

export default AutoComplete;