import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Accordion, Form, Button } from '@themesberg/react-bootstrap'
import { useForm } from 'react-hook-form'
import ReactQuill from 'react-quill';
import Loader from '../../pages/loader';
import 'react-quill/dist/quill.snow.css';

export default (props) => {
  const id = props.id
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' })

  const [idTallaPalos, setIdTallaPalos] = useState(0)
  const [texto, setTexto] = useState('')
  const [mensajeBola, setMensajeBola] = useState('')
  const [bola, setBola] = useState('')
  const [valHierro, setValHierro] = useState('')
  const [valDriver, setValDriver] = useState('')
  const [loftDriver, setLoftDriver] = useState('')
  const [tablaHierro, setTablaHierro] = useState([])
  const [tablaRecomendacion, setTablaRecomendacion] = useState()
  const [total, setTotal] = useState()
  const [totalIndice, setTotalIndice] = useState()
  const [totalHierro, setTotalHierro] = useState()
  const [load, setLoad] = useState(true)
  const [idProfesionalAct, setIdProfesionalAct] = useState()


  const handleChange = (html) => {
    setTexto(html);
  };

  function getTextRecomendacion() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/texto-recomendacion-get`, {
        params: { id },
      })
      .then((response) => {
        if (response.data[0] != '') {
          const flex = response.data[0].nuevoFlex || response.data[0].flex;
          const pesoVarilla = response.data[0].nuevoPeso || response.data[0].pesoVarilla;
          const flexDri = response.data[0].nuevoFlexDr || response.data[0].fleDriver;
          const pesoDri = response.data[0].nuevoPesoDr || response.data[0].pesoDriver;
          setTexto(`Estimad@ ${response.data[0].cliente} <br /><br /> Gracias por permitirnos hacer parte de la mejora en su juego , nuestra recomendación para escoger sus hierros e hibridos se enfocó en escoger varas con una flexibilidad (${flex}) con pesos cercanos a los (${pesoVarilla} gr.) Lo que resultara en un ritmo de swing fácil que se ajuste a su velocidad logrando así tiros con una trayectoria media y una buena distancia , su Angulo de salida optimo de ${response.data[0].angSalida} º y un Spin optimo de  ${response.data[0].spin} rpm <br/><br/>En el caso del Driver y Maderas nuestra recomendación es un flex (${flexDri}) con pesos en su rango de dureza cercanos a los ( ${pesoDri} gr ) le otorgaran el control para obtener un Spin debajo de las ${response.data[0].spinDriver} rpm, y un angulo de salida optimo de ${response.data[0].angDriver} º.`)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getRecTeoEquipo() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/recomendacion-teorica-profesional-get`, {
        params: { id },
      })
      .then((response) => {
        if (response.data[0] !== '') {
          const data = response.data[0];

          const totalHierros = ['numero_hierros', 'numero_hierros4', 'numero_hierros5', 'numero_hierros6']
            .reduce((acc, key) => acc + (data[key] ? parseInt(data[key]) : 0), 0);

          const totalMaderas = Object.keys(data)
            .filter((key) => key.startsWith('numero_maderas'))
            .reduce((acc, key) => acc + parseInt(data[key]), 0);

          const totalHibridos = Object.keys(data)
            .filter((key) => key.startsWith('numero_hibridos') && data[key] === '1')
            .reduce((acc, key) => acc + parseInt(data[key]), 0);

          const totalDriving = Object.keys(data)
            .filter((key) => key.startsWith('numero_driving') && data[key] === '1')
            .reduce((acc, key) => acc + parseInt(data[key]), 0);

          setTotalHierro(totalHierros)
          setTotal(totalMaderas + totalHibridos + totalDriving)
          setTotalIndice(totalHierros + totalMaderas + totalHibridos + totalDriving)
          setTablaRecomendacion(response.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getHierros() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-id-ok-get`, {
        params: { id },
      })
      .then((response) => {

        if (response.data != 'Wrong getIdOkCabezaHierros') {
          setValHierro(response.data[0].distancia)
          // const idModelo = response.data[0].id_modelo_cabeza
          // axios
          //   .get(`${process.env.REACT_APP_BASE_URL}/modelo-hierros-all-get`, {
          //     params: { idModelo },
          //   })
          //   .then((response) => {
          //     if (response != 'vacio') {
          //       setTablaHierro(response.data)
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err)
          //   })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getDriver() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-id-ok-get`, {
        params: { id },
      })
      .then((response) => {
        if (response.data != 'Wrong getCabezasDriverOk') {
          setValDriver(response.data[0].distancia)
        }
        setLoad(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  function getRecomendacionProfesional() {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/recomendacion-profesional-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdProfesionalAct(response.data[0].id)
          defaultValues.numero_hierros = response.data[0].numero_hierros
          defaultValues.numero_maderas = response.data[0].numero_maderas
          defaultValues.numero_wedges = response.data[0].numero_wedges
          defaultValues.numero_hibridos = response.data[0].numero_hibridos
          defaultValues.numero_driving = response.data[0].numero_driving
          defaultValues.numero_hierros4 = response.data[0].numero_hierros4
          defaultValues.numero_hierros5 = response.data[0].numero_hierros5
          defaultValues.numero_hierros6 = response.data[0].numero_hierros6
          defaultValues.numero_hierros7 = response.data[0].numero_hierros7
          defaultValues.numero_hierros8 = response.data[0].numero_hierros8
          defaultValues.numero_hierros9 = response.data[0].numero_hierros9
          defaultValues.numero_hierrosPW = response.data[0].numero_hierrosPW
          defaultValues.numero_maderas2 = response.data[0].numero_maderas2
          defaultValues.numero_maderas3 = response.data[0].numero_maderas3
          defaultValues.numero_maderas4 = response.data[0].numero_maderas4
          defaultValues.numero_hibridos2 = response.data[0].numero_hibridos2
          defaultValues.numero_hibridos3 = response.data[0].numero_hibridos3
          defaultValues.numero_hibridos4 = response.data[0].numero_hibridos4
          defaultValues.numero_hibridos5 = response.data[0].numero_hibridos5
          defaultValues.numero_wedges2 = response.data[0].numero_wedges2
          defaultValues.numero_wedges3 = response.data[0].numero_wedges3
          defaultValues.numero_wedges4 = response.data[0].numero_wedges4
          defaultValues.numero_wedges5 = response.data[0].numero_wedges5
          defaultValues.numero_wedges6 = response.data[0].numero_wedges6
          defaultValues.numero_wedges7 = response.data[0].numero_wedges7
          defaultValues.numero_wedges8 = response.data[0].numero_wedges8
          defaultValues.numero_wedges9 = response.data[0].numero_wedges9
          defaultValues.numero_wedges10 = response.data[0].numero_wedges10
          defaultValues.numero_driving2 = response.data[0].numero_driving2
          defaultValues.numero_driving3 = response.data[0].numero_driving3
          defaultValues.numero_driving4 = response.data[0].numero_driving4
          defaultValues.loftDriver = response.data[0].loftDriver
          defaultValues.bolas = response.data[0].bolas
          setMensajeBola(response.data[0].mensaje_bolas)
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getHierros()
    getDriver()
    getTextRecomendacion()
    getRecTeoEquipo()
    getRecomendacionProfesional()

  }, [id])

  const bolas = (id) => {
    let html = ''
    let htmlVar = ''
    switch (id) {
      case '1':
        html = (<div width="100%">
          <h6>LOW SPIN:</h6>
          <p>
            Bridgestone Laddie Extreme, Bridgestones New e6 Lady, Callaway ERC
            Soft, Callaway HEX Warbird, Callaway Chrome Soft,
            <br /> Pinnacle Rush / soft, Taylormade Distance +, TaylorMade
            Noodle Easy Distance, Titleist AVX, Titleist DT TruSoft
          </p>
        </div>)
        htmlVar = (`<div width="100%">
        <h6>LOW SPIN:</h6>
        <p>
          Bridgestone Laddie Extreme, Bridgestones New e6 Lady, Callaway ERC
          Soft, Callaway HEX Warbird, Callaway Chrome Soft,
          <br /> Pinnacle Rush / soft, Taylormade Distance +, TaylorMade
          Noodle Easy Distance, Titleist AVX, Titleist DT TruSoft
        </p>
      </div>`)
        setBola(html)
        setMensajeBola(htmlVar)
        break
      case '2':
        html = (<div width="100%">
          <h6>MID SPIN:</h6>
          <p>
            Bridgestone e12 Speed Golf Bal, Callaway Superhot 70, Maxfli
            Revolution <br />
            Spin, TaylorMade Soft Response, TaylorMade TP5x, Titleist
            Velocity, Titleist’s TruFeel, TaylorMade Noodle Neon, Titleist’s
            PROV1
          </p>
        </div>)
        htmlVar = (`<div width="100%">
        <h6>MID SPIN:</h6>
        <p>
          Bridgestone e12 Speed Golf Bal, Callaway Superhot 70, Maxfli
          Revolution <br />
          Spin, TaylorMade Soft Response, TaylorMade TP5x, Titleist
          Velocity, Titleist’s TruFeel, TaylorMade Noodle Neon, Titleist’s
          PROV1
        </p>
      </div>`)
        setBola(html)
        setMensajeBola(htmlVar)
        break
      case '3':
        html = (
          <div width="100%">
            <h6>HIGH SPIN:</h6>
            <p>
              Bridgestone TOUR B XS, Callaway HEX Black Tour, Callaway Chrome
              Soft X,
              <br />
              Callaway Supersoft, TaylorMade New Tour Response, TaylorMade TP5x,
              TITLEIST TOUR SPEED, Titleist Tour Soft, TITLEIST PRO V1 X
            </p>
          </div>)
        htmlVar = (`
        <div width="100%">
            <h6>HIGH SPIN:</h6>
            <p>
              Bridgestone TOUR B XS, Callaway HEX Black Tour, Callaway Chrome
              Soft X,
              <br />
              Callaway Supersoft, TaylorMade New Tour Response, TaylorMade TP5x,
              TITLEIST TOUR SPEED, Titleist Tour Soft, TITLEIST PRO V1 X
            </p>
          </div>
        `)
        setBola(html)
        setMensajeBola(htmlVar)
        break

      default:
        break
    }
  }
  const onSubmit = (data) => {
    if (idProfesionalAct) {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}/recomendacion-profesional-upd`, {
          data: {
            id: idProfesionalAct,
            data: data,
            detalle: texto,
            mensajeBolas: mensajeBola
          }
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/recomendacion-profesional-ins`, {
          data: data,
          detalle: texto,
          mensajeBolas: mensajeBola
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  let indiceHierro = totalIndice;
  let totalHierroCal = totalHierro;
  let firstHierro = parseInt(valHierro) + (parseInt(totalHierroCal) * 10)
  let valorDivido = (parseInt(valDriver) - firstHierro) / (parseInt(total) + 1);
  let indiceIronDri = -1;
  let ultimoValorI = 0;
  let primerValorI = 0;
  let valorI = 0
  let valor = 0
  const calcularValorCampo = (tipoPalo, valorInicial, indice) => {
    let incremento = 0;
    let resultado = 0;

    if (tipoPalo.startsWith('i')) {
      if (primerValorI === 0) {
        primerValorI = parseInt(valorInicial) + (10 * indice);
        resultado = primerValorI;
      } else {
        ultimoValorI = parseInt(valorInicial) + (10 * indice);
        resultado = ultimoValorI;
      }
    } else if (tipoPalo.startsWith('HYB')) {
      if (valorI === 0) {
        valor = parseInt(valDriver) - valorDivido;
        valorI = valor;
        resultado = valor;
      } else {
        valor = valorI - valorDivido;
        valorI = valor;
        resultado = valor;
      }
    } else if (tipoPalo.startsWith('DRY')) {
      resultado = parseInt(ultimoValorI) + (20 * indice);
      return parseFloat(resultado.toFixed(1));
    } else {
      resultado = parseInt(valorInicial) + (incremento * indice);
    }
    return parseFloat(resultado.toFixed(1));
  }

  return (
    <Accordion.Item eventKey="8" className="accordion-item-info mt-4">
      {load ? (
        <Loader />
      ) : (
        <><Accordion.Button
          variant="link"
          className="w-100 d-flex justify-content-between accordion-button-info"
        >
          <span className="h6 mb-0 fw-bold">Recomendación del profesional</span>
        </Accordion.Button><Accordion.Body className="table-bording">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" value={id} {...register('id_informe_tecnico')} />
              <div className="row">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" colSpan={'6'}>
                        Recomendación
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Bolas
                        <Form.Group>
                          <Form.Select
                            defaultValue="0"
                            {...register('bolas')}
                            onChange={(event) => {
                              bolas(event.target.value)
                            }}
                          >
                            <option value="">Seleccione</option>
                            <option value="1">LOW SPIN</option>
                            <option value="2">MID SPIN</option>
                            <option value="3">HIGH SPIN</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                    {mensajeBola ? (
                      <tr>
                        <td dangerouslySetInnerHTML={{ __html: mensajeBola }} />
                      </tr>
                    ) : (
                      <tr>
                        <td>{bola}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {valHierro && valDriver ? <div className="row">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <ReactQuill value={texto} onChange={handleChange} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {tablaRecomendacion && <table className="table table-bordered ">
                  <thead>
                    <tr>
                      <th scope="col">Tipo de palo</th>
                      <th scope="col">LOFT (°)</th>
                      <th scope="col">Carry Garantizado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tablaRecomendacion.driver?.length > 0 && (<tr className="elim-tr-1">
                      <td>DRIVER {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('loftDriver')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loftDriver}
                      </td>
                      <td>
                        <input type="text" name="loftDriver" className="loft-driver-es form-control" value={valDriver} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_maderas > 0 && (<tr className="elim-tr-2">
                      <td>W3 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_maderas')} />*/}</td>
                      <td>
                        15
                      </td>
                      <td>
                        <input type="text" name="numero_maderas" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)}/>
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_maderas2 > 0 && (<tr className="elim-tr-2">
                      <td>W5  {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_maderas2')} />*/}</td>
                      <td>
                        18
                      </td>
                      <td>
                        <input type="text" name="numero_maderas2" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_maderas3 > 0 && (<tr className="elim-tr-2">
                      <td>W7 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_maderas3')} />*/}</td>
                      <td>
                        21
                      </td>
                      <td>
                        <input type="text" name="numero_maderas3" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)}/>
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_maderas4 > 0 && (<tr className="elim-tr-2">
                      <td>W9 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_maderas4')} />*/}</td>
                      <td>
                        27
                      </td>
                      <td>
                        <input type="text" name="numero_maderas4" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)}/>
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_hibridos > 0 && (<tr className="elim-tr-2">
                      <td>HYB2 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hibridos')} />*/}</td>
                      <td>
                        17
                      </td>
                      <td>
                        <input type="text" name="numero_hibridos" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_driving > 0 && (<tr className="elim-tr-2">
                      <td>ID2 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_driving')} />*/}</td>
                      <td>
                        17
                      </td>
                      <td>
                        <input type="text" name="numero_driving" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}

                    {tablaRecomendacion.numero_hibridos2 > 0 && (<tr className="elim-tr-2">
                      <td>HYB3 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hibridos2')} />*/}</td>
                      <td>
                        19
                      </td>
                      <td>
                        <input type="text" name="numero_hibridos2" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_driving2 > 0 && (<tr className="elim-tr-2">
                      <td>ID3 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_driving2')} />*/}</td>
                      <td>
                        19
                      </td>
                      <td>
                        <input type="text" name="numero_driving2" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_hibridos3 > 0 && (<tr className="elim-tr-2">
                      <td>HYB4 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hibridos3')} />*/}</td>
                      <td>
                        22
                      </td>
                      <td>
                        <input type="text" name="numero_hibridos3" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_driving3 > 0 && (<tr className="elim-tr-2">
                      <td>ID4 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_driving3')} />*/}</td>
                      <td>
                        22
                      </td>
                      <td>
                        <input type="text" name="numero_driving3" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_hibridos4 > 0 && (<tr className="elim-tr-2">
                      <td>HYB5 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hibridos4')} />*/}</td>
                      <td>
                        26
                      </td>
                      <td>
                        <input type="text" name="numero_hibridos4" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_driving4 > 0 && (<tr className="elim-tr-2">
                      <td>ID5 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_driving4')} />*/}</td>
                      <td>
                        25
                      </td>
                      <td>
                        <input type="text" name="numero_driving4" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_hibridos5 > 0 && (<tr className="elim-tr-2">
                      <td>HYB6 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hibridos5')} />*/}</td>
                      <td>
                        30
                      </td>
                      <td>
                        <input type="text" name="numero_hibridos5" className="otros-cal form-control" value={calcularValorCampo('HYB', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {(tablaRecomendacion.numero_hierros > 0 || tablaRecomendacion.numero_hierros_II > 0) && (<tr className="elim-tr-2">
                      <td>i3 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hierros')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loft_sett_3}
                      </td>
                      <td>
                        <input type="text" name="numero_hierros" className="otros-cal form-control" value={calcularValorCampo('i', valHierro, indiceHierro--)}/>
                      </td>
                    </tr>
                    )}
                    {(tablaRecomendacion.numero_hierros4 > 0 || tablaRecomendacion.numero_hierros4_II > 0) && (<tr className="elim-tr-2">
                      <td>i4 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hierros4')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loft_sett_4}
                      </td>
                      <td>
                        <input type="text" name="numero_hierros4" className="otros-cal form-control" value={calcularValorCampo('i', valHierro, indiceHierro--)}/>
                      </td>
                    </tr>
                    )}
                    {(tablaRecomendacion.numero_hierros5 > 0 || tablaRecomendacion.numero_hierros5_II > 0) && (<tr className="elim-tr-2">
                      <td>i5 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hierros5')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loft_sett_5}
                      </td>
                      <td>
                        <input type="text" name="numero_hierros5" className="otros-cal form-control" value={calcularValorCampo('i', valHierro, indiceHierro--)}  />
                      </td>
                    </tr>
                    )}
                    {(tablaRecomendacion.numero_hierros6 > 0 || tablaRecomendacion.numero_hierros6_II > 0) && (<tr className="elim-tr-2">
                      <td>i6 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hierros6')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loft_sett_6}
                      </td>
                      <td>
                        <input type="text" name="numero_hierros6" className="otros-cal form-control" value={calcularValorCampo('i', valHierro, indiceHierro--)}/>
                      </td>
                    </tr>
                    )}
                    {(tablaRecomendacion.numero_hierros7 > 0 || tablaRecomendacion.numero_hierros7_II > 0) && (<tr className="elim-tr-2">
                      <td>i7 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hierros7')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loft_sett_7}
                      </td>
                      <td>
                        <input type="text" name="numero_hierros7" className="otros-cal form-control" value={calcularValorCampo('i', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {(tablaRecomendacion.numero_hierros8 > 0 || tablaRecomendacion.numero_hierros8_II > 0) && (<tr className="elim-tr-2">
                      <td>i8 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hierros8')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loft_sett_8}
                      </td>
                      <td>
                        <input type="text" name="numero_hierros8" className="otros-cal form-control" value={calcularValorCampo('i', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {(tablaRecomendacion.numero_hierros9 > 0 || tablaRecomendacion.numero_hierros9_II > 0) && (<tr className="elim-tr-2">
                      <td>i9 {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hierros9')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loft_sett_9}
                      </td>
                      <td>
                        <input type="text" name="numero_hierros9" className="otros-cal form-control" value={calcularValorCampo('i', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {(tablaRecomendacion.numero_hierrosPW > 0 || tablaRecomendacion.numero_hierrosPW_II > 0) && (<tr className="elim-tr-2">
                      <td>PW {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_hierrosPW')} />*/}</td>
                      <td>
                        {tablaRecomendacion.loft_sett_PW}
                      </td>
                      <td>
                        <input type="text" name="numero_hierrosPW" className="otros-cal form-control" value={calcularValorCampo('i', valHierro, indiceHierro--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges > 0 && (<tr className="elim-tr-2">
                      <td>PW 43° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges')} />*/}</td>
                      <td>
                        43
                      </td>
                      <td>
                        <input type="text" name="numero_wedges" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri)}/>
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges2 > 0 && (<tr className="elim-tr-2">
                      <td>PW 48° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges2')} />*/}</td>
                      <td>
                        48
                      </td>
                      <td>
                        <input type="text" name="numero_wedges2" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges3 > 0 && (<tr className="elim-tr-2">
                      <td>AW 50° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges3')} />*/}</td>
                      <td>
                        50
                      </td>
                      <td>
                        <input type="text" name="numero_wedges3" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges4 > 0 && (<tr className="elim-tr-2">
                      <td>AW 52° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges4')} />*/}</td>
                      <td>
                        52
                      </td>
                      <td>
                        <input type="text" name="numero_wedges4" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges5 > 0 && (<tr className="elim-tr-2">
                      <td>SW 54° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges5')} />*/}</td>
                      <td>
                        54
                      </td>
                      <td>
                        <input type="text" name="numero_wedges5" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges6 > 0 && (<tr className="elim-tr-2">
                      <td>SW 56° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges6')} />*/}</td>
                      <td>
                        56
                      </td>
                      <td>
                        <input type="text" name="numero_wedges6" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges7 > 0 && (<tr className="elim-tr-2">
                      <td>LW 58° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges7')} />*/}</td>
                      <td>
                        58
                      </td>
                      <td>
                        <input type="text" name="numero_wedges7" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges8 > 0 && (<tr className="elim-tr-2">
                      <td>LW 60° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges8')} />*/}</td>
                      <td>
                        60
                      </td>
                      <td>
                        <input type="text" name="numero_wedges8" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges9 > 0 && (<tr className="elim-tr-2">
                      <td>LW 62° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges9')} />*/}</td>
                      <td>
                        62
                      </td>
                      <td>
                        <input type="text" name="numero_wedges9" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                    {tablaRecomendacion.numero_wedges10 > 0 && (<tr className="elim-tr-2">
                      <td>LW 64° {/*<input type="text" name="loftDriver" disabled className="loft-driver-es" style={{ backgroundColor: 'transparent', border: 'none',fontSize: '15px',fontWeight: 600}} value="" {...register('numero_wedges10')} />*/}</td>
                      <td>
                        64
                      </td>
                      <td>
                        <input type="text" name="numero_wedges10" className="otros-cal form-control" value={calcularValorCampo('DRY', valHierro, indiceIronDri--)} />
                      </td>
                    </tr>
                    )}
                  </tbody>
                </table>}
              </div> : <p>Debe seleccionar un hierro de varilla y un driver</p>}
              <div className="mt-3">
                {idTallaPalos ? (
                  <Button variant="success" type="submit">
                    Actualizar
                  </Button>
                ) : (
                  <Button variant="primary" type="submit">
                    Guardar
                  </Button>
                )}
              </div>
            </Form>
          </Accordion.Body></>
      )}
    </Accordion.Item>
  )
}
