
export const RoutesPage = {
    // pages
    Signin: { path: "/" },
    home: { path: "/home" },
    client: { path: "/client" },
    clientProduct: { path: "/client/product/:categoryId/:idFitting" },
    FittingClient: { path: "/fitting-client" },
    Fitting: { path: "/fitting" },
    Varas: { path: "/varas" },
    ModeloVarilla: { path: "/mdv-palos-cortos" },
    MdcPalosLargos: { path: "/mdc-palos-largos" },
    MdcHierros: { path: "/mdc-hierros" },
    MdcIronDriving: { path: "/mdc-iron-driving" },
    MdcPutts: { path: "/mdc-putts" },
    LoftDriver: { path: "/loft-driver" },
    LoftHierros: { path: "/loft-hierros" },
    ModeloDriver: { path: "/mdv-palos-largos" },
    TipoCabeza: { path: "/tipo-cabeza" },
    TipoPalo: { path: "/tipo-palo" },
    FabricaVara: { path: "/fabrica-vara" },
    Flexes: { path: "/flexes" },
    Marcas: { path: "/marcas" },
    Wedges: { path: "/wedges" },
    Bolas: { path: "/bolas" },
    Alcances: { path: "/alcances" },
    Clubs: { path: "/clubs" },
    GripsMedidas: { path: "/grips-medidas" },
    GripsReferencia: { path: "/grips-referencia" },
    Handicaps: { path: "/handicaps" },
    Pulgadas: { path: "/pulgadas" },
    LongVara: { path: "/long-vara" },

    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // docs
    InformePdf: { path: "/informe-pdf/:id" },
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components pages
    FittingDetailsNew: { path: "/fitting/fitting-new" },
    FittingDetails: { path: "/fitting/fitting-details/:id" },
    VarasNew: { path: "/varas/varas-new" },
    VarasDetails: { path: "/varas/varas-details/:id" },
    LoftHierrosNew: { path: "/loft-hierros/loft-hierro-new" },
    LoftHierrosDetails: { path: "/loft-hierros/loft-hierro-details/:id" },

    // components 
    InformeActualizar: { path: "/fitting/fitting-details/functions-actualizar-informe/:id" },

    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};