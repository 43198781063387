import React from 'react';
import Login from "../pages/Signin";
import Home from "../pages/HomePage";
import {jwtDecode} from 'jwt-decode';

function parseJwt(token) {
  try {
    if (token) {
      let decodedToken = jwtDecode(token);
      let currentDate = new Date();

      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        console.log("Token expired.");
        localStorage.clear();
        return false;
      } else {
        console.log("Valid token");
        return true;
      }
    }
    return false;
  } catch (e) {
    localStorage.clear();
    console.log(e);
    return false;
  }
}

let tokenExistAndStillValid = parseJwt(localStorage.getItem('token'));

const Main = () => {
  if (!tokenExistAndStillValid) {
    return <Login />;
  }

  return <Home />;
};

export default Main;
