import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Datetime from 'react-datetime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { ListValor, ListName } from './Functions/functions-listas-select'
import Swal from 'sweetalert2'
import { Col, Accordion, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'


export default (props) => {

  const id = props.id
  const idCli = props.idCli

  const [birthday, setBirthday] = useState('')
  const [fecha, setFecha] = useState('')

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(
    { mode: 'onBlur' }
  )

  function getClientesFitting() {
    if (id) {
      let defaultValues = {};
      axios.get(`${process.env.REACT_APP_BASE_URL}/client`, {
        params: { id }
      })
        .then((response) => {
          defaultValues.id = response.data[0].id;
          defaultValues.nombre = response.data[0].name;
          defaultValues.fecha = response.data[0].created_at;
          defaultValues.fecha = moment(response.data[0].created_at).format('YYYY/MM/DD');
          setFecha(moment(response.data[0].created_at).format('YYYY/MM/DD'));
          defaultValues.telefono = response.data[0].telefono;
          defaultValues.email = response.data[0].email;
          defaultValues.fechaNacimiento = response.data[0].fecha_nacimiento;
          defaultValues.fechaNacimiento = moment(response.data[0].fecha_nacimiento).format('YYYY/MM/DD');
          setBirthday(moment(response.data[0].fecha_nacimiento).format('YYYY/MM/DD'));
          defaultValues.estatura = response.data[0].estatura;
          defaultValues.diestroZurdo = response.data[0].diestroZurdo;
          defaultValues.club = response.data[0].id_club;
          defaultValues.handicap = response.data[0].id_handicap;
          defaultValues.pulgadas = response.data[0].munecaSuelo;
          defaultValues.medidaGrip = response.data[0].id_grip_medida;
          defaultValues.referenciaGrip = response.data[0].id_grip_referencia;
          defaultValues.alcance = response.data[0].status;
          reset({ ...defaultValues });
        })
        .catch((err) => {
          console.log(err);
        })
    }

  }

  useEffect(() => {
    if (id) {
      getClientesFitting();
    }
  }, [id]);

  function getClientes() {
    if (idCli) {
      let defaultValues = {};
      axios.get(`${process.env.REACT_APP_BASE_URL}/client-get`, {
        params: { idCli }
      })
        .then((response) => {
          defaultValues.id = response.data[0].id;
          defaultValues.nombre = response.data[0].name;
          defaultValues.fecha = response.data[0].created_at;
          defaultValues.telefono = response.data[0].telefono;
          defaultValues.email = response.data[0].email;
          defaultValues.fechaNacimiento = response.data[0].fecha_nacimiento;
          defaultValues.estatura = response.data[0].estatura;
          defaultValues.diestroZurdo = response.data[0].diestroZurdo;
          defaultValues.club = response.data[0].id_club;
          defaultValues.handicap = response.data[0].id_handicap;
          defaultValues.pulgadas = response.data[0].munecaSuelo;
          defaultValues.medidaGrip = response.data[0].id_grip_medida;
          defaultValues.referenciaGrip = response.data[0].id_grip_referencia;
          defaultValues.alcance = response.data[0].status;
          reset({ ...defaultValues });
        })
        .catch((err) => {
          console.log(err);
        })
    }

  }

  useEffect(() => {
    if (idCli) {
      getClientes();
    }
  }, [idCli]);



  const onSubmit = (data) => {
    if (id) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/upd-client`, {
          data: data,
          fecha: fecha,
          fechaNacimiento: birthday
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 2000,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      let idCliente = 0
      if (idCli > 0) {
        idCliente = idCli
      }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/new-client`, {
          data: data,
          idCli: idCliente,
          fecha: fecha,
          fechaNacimiento: birthday
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 2000,
          })
          window.location = `#/fitting/fitting-details/${res.data[0].id}`
        })
        .catch((err) => {
          console.log(err)
        })
    }

  }

  return (
    <Accordion.Item eventKey="1" className="accordion-item-info">
      <Accordion.Button
        variant="link"
        className="w-100 d-flex justify-content-between accordion-button-info"
      >
        <span className="h6 mb-0 fw-bold">Información del Jugador</span>
      </Accordion.Button>
      <Accordion.Body>
        <Card.Body className="py-2 px-0">
          <Form onSubmit={handleSubmit(onSubmit)}>
            {
              id > 0 && <input type='hidden' {...register('id')} />
            }
            {
              idCli > 0 && <input type='hidden' {...register('id')} />
            }

            <Row>
              <h5 className="my-4">Información del contacto</h5>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese su nombre completo"
                    {...register('nombre', { required: true })}
                  />
                  {errors.nombre?.type === 'required' && (
                    <small className="textRequired">
                      Este campo es requerido
                    </small>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="fecha">
                  <Form.Label>Fecha</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={setFecha}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={
                            fecha
                              ? moment(fecha).format('YYYY/MM/DD')
                              : ''
                          }
                          {...register('fecha')}
                          placeholder="mm/dd/yyyy"
                          onFocus={openCalendar}
                          onChange={() => { }}
                        />
                      </InputGroup>
                    )}
                  />
                  {errors.fecha?.type === 'required' && (
                    <small className="textRequired">
                      Este campo es requerido
                    </small>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Teléfono"
                    {...register('telefono')}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="emal">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@company.com"
                    {...register('email', {
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                    })}
                  />
                  {errors.email?.type === 'pattern' && (
                    <p>El formato del email es incorrecto</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="birthday">
                  <Form.Label>Fecha nacimiento</Form.Label>
                  <Datetime
                    timeFormat={true}
                    onChange={setBirthday}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={
                            birthday
                              ? moment(birthday).format('YYYY/MM/DD')
                              : ''
                          }
                          placeholder="mm/dd/yyyy"
                          onFocus={openCalendar}
                          onChange={() => { }}
                          {...register('fechaNacimiento')}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <Form.Label>Estatura</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="1.80"
                    {...register('estatura')}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Diestro/Zurdo</Form.Label>
                  <Form.Select defaultValue="0" {...register('diestroZurdo')}>
                    <option value="Diestro">Diestro</option>
                    <option value="Zurdo">Zurdo</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <h5 className="my-4">Información del jugador</h5>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Club</Form.Label>
                  <Form.Select defaultValue="0" {...register('club')}>
                    <ListName url="clubs" />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Handicap</Form.Label>
                  <Form.Select defaultValue="0" {...register('handicap')}>
                    <ListValor url="handicaps" />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Dist. De la muñeca al suelo</Form.Label>
                  <Form.Select defaultValue="0" {...register('pulgadas')}>
                    <ListValor url="pulgadas" />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Medida Grip</Form.Label>
                  <Form.Select defaultValue="0" {...register('medidaGrip')}>
                    <ListName url="medida-grip" />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Referncia Grip</Form.Label>
                  <Form.Select defaultValue="0" {...register('referenciaGrip')}>
                    <ListName url="referencia-grip" />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Alcance</Form.Label>
                  <Form.Select defaultValue="0" {...register('alcance')}>
                    <ListName url="alcances" />
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <div className="mt-3">
              {
                id > 0 || idCli > 0
                  ?
                  <Button variant="success" type="submit">
                    Actualizar
                  </Button>
                  :
                  <Button variant="primary" type="submit">
                    Guardar
                  </Button>
              }

            </div>
          </Form>
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item>
  )
}
