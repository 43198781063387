import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import { Form, Button, Modal } from '@themesberg/react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Carousel from 'react-bootstrap/Carousel';

const Slider = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [sliderImagenes, setSliderImagenes] = useState([]);
    const id = props.id;
    const [img, setImg] = useState(null);
    const {
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onBlur' })

    function getIdentActual() {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/slider-all-get`, {
                params: { id },
            })
            .then((response) => {
                if (response !== 'vacio') {
                    setSliderImagenes(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getIdentActual();
    }, []);

    const onSubmit = (key) => {
        const formData = new FormData();
        formData.append('image', img);

        if (!img) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No se ha seleccionado ninguna imagen.',
            });
            return;
        }
        formData.append('id', id);
        formData.append('folder', 'imagenes-ide-actual');
        fetch(`${process.env.REACT_APP_BASE_URL}/imagenes-ins`, {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res === 'error') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'the document size is too large... maximum size allowed is 2MB. !',
                    });
                } else {
                    let ima1 = '';
                    if (Array.isArray(res)) {
                        for (let i = 0; i < res.length; i++) {
                            const currentItem = res[i];

                            if (currentItem !== null && currentItem !== undefined) {
                                if (currentItem.img1 !== null && currentItem.img1 !== undefined) {
                                    ima1 = currentItem.img1;
                                }
                            }
                            axios.put(`${process.env.REACT_APP_BASE_URL}/img-equ-actual-upd`, {
                                id: key,
                                img: ima1,
                            })
                                .then((response) => {
                                    Swal.fire({
                                        html: '<i>Process complete!</i>',
                                        icon: 'success',
                                        timer: 1000,
                                    });
                                    window.location.reload()
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setImg(file);
    };

    return (
        <>
            <Button className="btn btn-warning btn-sm" onClick={handleShow}>
                Ver Fotos
            </Button>
            <Modal className="viewDoc" size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{ textAlign: 'center' }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="title-sec-eng" style={{ textAlign: 'center' }}>
                        <span className="title-sec-esp">Slider Imagenes</span><br />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel data-bs-theme="dark">
                        {Array.isArray(sliderImagenes) ? (
                            sliderImagenes.map((val, key) => (
                                <Carousel.Item key={key}>
                                    <img
                                        className="d-block w-100"
                                        src={`${process.env.REACT_APP_BASE_URL}/imagenes-ide-actual/${val.imagen}`}
                                        alt={`Slider ${key + 1}`}
                                    />
                                    <div className="row" style={{ padding: '0px 22px' }}>
                                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                            <div className="mt-2 bottom-section">
                                                <p style={{ float: 'left' }} className="textLeft">Si requiere actualizar este documento, adjúntelo aquí </p>
                                            </div>
                                        </div>
                                        <Form onSubmit={handleSubmit(() => onSubmit(val.id))} style={{ paddingBottom: '20px', marginTop: '10px', position: 'relative' }}>
                                            <Form.Group className="d-flex justify-content-center">
                                                <Form.Control
                                                    type="file"
                                                    placeholder="ZIP"
                                                    onChange={handleFileUpload}  // Usar la función handleFileUpload para cargar la imagen
                                                />
                                                <Button variant="success" type="submit" style={{ marginLeft: '10px', backgroundColor: 'green', position: 'relative', zIndex: '9999' }}>
                                                    Guardar
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </Carousel.Item>
                            ))
                        ) : (
                            <p>Error: sliderImagenes no es un array.</p>
                        )}
                    </Carousel>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Slider;
