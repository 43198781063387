
import React, { useState, useEffect } from 'react'
import { Col, Accordion, Row } from '@themesberg/react-bootstrap';
import Clients from "../../../components/fitting-components/Clients-informe";
import InformeActual from "../../../components/fitting-components/Informacion-equipo-actual";
import TallaPalos from "../../../components/fitting-components/Talla-palos";
import SeleccionCabezaHierros from "../../../components/fitting-components/Seleccion-cabeza-hierros";
import SeleccionVarillaHierros from "../../../components/fitting-components/Seleccion-varilla-hierros";
import SeleccionDriver from "../../../components/fitting-components/Seleccion-driver";
import RecomendacionTeorica from "../../../components/fitting-components/Recomendacion-teorica";
import RecomendacionProfesional from "../../../components/fitting-components/Recomendacion-profesional"
import { useParams, Link } from 'react-router-dom';


export default () => {
  const { id } = useParams();

  return (
    <article className='table_class'>
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col md={10} className="d-block mb-4 mb-md-0">
          <h1 className="h2">Informe técnico</h1>
          <p className="mb-0">
            Descrpción de infome técnico
          </p>
        </Col>
        <Col md={2} className="d-block mb-4 mb-md-0">
          <Link to={`/informe-pdf/${id}`}>
            <button type="button" className='btn btn-warning'>Ver informe PDF</button>
          </Link>
        </Col>
      </Row>
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-md-0">
          <Accordion className="" defaultActiveKey="0">
            <Clients id={id} />
            <InformeActual id={id} />
            <TallaPalos id={id} />
            <SeleccionCabezaHierros id={id} />
            <SeleccionVarillaHierros id={id} />
            <SeleccionDriver id={id} />
            <RecomendacionTeorica id={id} />
            <RecomendacionProfesional id={id} />
          </Accordion>
        </Col>
      </Row>
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-md-0">

        </Col>
      </Row>
    </article>
  )
}  
