import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2'

const Pdf = props => {
    // const [adjTaxId, setAdjTaxId] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const attach = props.attach;

    // const selectHundles = e => {
    //     setAdjTaxId(e.target.files[0]);
    // }

    // const onSubmit = () => {  
    //     const formData = new FormData()
    //     formData.append('image', adjTaxId)
    //     formData.append('oldImage', props.doc)
    //     formData.append('id', props.taxId)
    //     formData.append('fileDoc', props.file)
    //     formData.append('doc', props.typeDoc)
    //     fetch(`${process.env.REACT_APP_BASE_URL}/update/post`,{
    //         method: 'PUT',
    //         body: formData
    //     })
    //     .then(res => res.json())
    //     .then(res => {
    //         if(res.status == "error"){
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Oops...',
    //                 text: 'the document size is too large... maximum size allowed is 2MB. !'
    //             })
    //         }else{
    //             Swal.fire({html: "<i>Upload success!</i>",icon:'success',timer:2000})
    //             window.location.reload();    
    //         }
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     })
        
    // }

    let url = `${process.env.REACT_APP_BASE_URL}/${props.folder}/${props.doc}`;
    switch (attach) {
        case 1:
            var titleEng = "Imagen 1";
            break;    
        case 2:
            var titleEng = "Imagen 2";
            break;    
        case 3:
            var titleEng = "Imagen 3";
        break;    
        default: 
            break;
    }
    return (
        <>
            <Button className="btn btn-next nuestroinput" onClick={handleShow}>
                Ver Imagen
            </Button>
            <Modal className="viewDoc" size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{textAlign:"center"}}>
                    <Modal.Title id="contained-modal-title-vcenter" className="title-sec-eng" style={{textAlign:"center"}}>
                        <span className="title-sec-esp" >{titleEng}</span><br/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe src={url} style={{width:'100%', height:'50vh'}}></iframe>
                </Modal.Body>
                {/* <Modal.Footer>
                    <div className="row" style={{padding:'0px 22px'}}>    
                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="mt-2 bottom-section">
                                <p style={{float:'left'}} className="textLeft">Si requiere actualizar este documento, adjúntelo aquí </p>
                            </div>
                        </div>    
                        {
                            adjTaxId ? 
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <input type="file" id="idDocUpd" onChange={selectHundles} />
                                <label htmlFor="idDocUpd" style={{float:'right'}}> <i className="fas fa-upload"></i> <span>Actualizar documento</span> </label> 
                            </div>
                            :
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <input type="file" id="idDoc" onChange={selectHundles} />
                                <label htmlFor="idDoc" style={{float:'right'}}> <i className="fas fa-upload"></i> <span> Adjuntar documento</span> </label> 
                            </div>
                        }
                    </div>
                    {
                        adjTaxId && (
                            <div className="row start-section pdf-upd">
                                <div id="buttons">
                                    <button type="button" onClick={onSubmit} id="nextBtn" className="btn btn-next" 
                                    >Save / Guardar</button>
                                </div>
                            </div>
                        )
                    }
                    
                </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default Pdf;
