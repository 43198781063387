import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{' '}
        <select
          name="datatable-basic_length"
          aria-controls="datatable-basic"
          className="form-control form-control-sm"
          onChange={(e) => onSizePerPageChange(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>{' '}
      </label>
    </div>
  ),
});

const Fitting = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const storedIdClient = localStorage.getItem('id');
    if (storedIdClient) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/informes-tecnicos-clientes`, {
          params: { idClient: storedIdClient },
        })
        .then((response) => {
          if (response.data !== 'vacio') {
            setData(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const { SearchBar } = Search;

  return (
    <>
      <ToolkitProvider
        data={data}
        keyField="id"
        columns={[
          {
            dataField: 'name',
            text: 'Name',
            sort: true,
          },
          {
            dataField: 'created_at',
            text: 'Fecha',
            sort: true,
            formatter: (cell) => {
              return new Date(cell).toLocaleDateString();
            },
          },
          {
            dataField: 'id',
            text: 'Action',
            formatter: (cellContent, row) => {
              return (
                <Link to={`/informe-pdf/${row.id}`}>
                  <button type="button" className='btn btn-warning btn-sm'>Ver informe PDF</button>
                </Link>
              );
            },
            hidden: false,
            checked: true,
            editable: false,
          },
        ]}
        search
      >
        {(props) => (
          <div className="table_class py-4">
            <div id="datatable-basic_filter" className="dataTables_filter px-1 pb-4">
              <label>
                Search:
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  {...props.searchProps}
                />
              </label>
            </div>
            {Array.isArray(data) && data.length > 0 ? (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            ) : (
              <p>No hay datos disponibles</p>
            )}
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default Fitting;
