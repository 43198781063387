import React, { useState, useEffect } from 'react'
import {
  Accordion,
  Form,
} from '@themesberg/react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Swal from 'sweetalert2'
import { ListName } from './Functions/functions-listas-select'
import "../../assets/css/style.css";

export default (props) => {

  const id = props.id
  const [idCabezaHierros, setIdCabezaHierros] = useState(0)
  const [idCabezaHierrosAct, setIdCabezaHierrosAct] = useState(0)
  const [idCabezaHierrosActual, setIdCabezaHierrosActual] = useState(0)
  const [modeloCabeza, setModeloCabeza] = useState('')
  const [tipoCabeza, setTipoCabeza] = useState('')
  const [loftCabeza, setLoftCabeza] = useState('')
  const [tablaCabezasHierros, setTablaCabezasHierros] = useState([])

  function getCabezaHierros() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-all-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setTablaCabezasHierros(response.data)
          setIdCabezaHierros(response.data[0].id_informe_tecnico)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function getHierrosequNuevo() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/hierros-equ-nuevo-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setModeloCabeza(response.data[0].nomModelo)
          setTipoCabeza(response.data[0].namTipo)
          setLoftCabeza(response.data[0].loftCabeza)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(
    { mode: 'onBlur' }
  )

  const getCabezaHierrosId = (id) => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-id-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdCabezaHierrosAct(response.data[0].id)
          defaultValues.id_cabeza_hierros = response.data[0].id
          defaultValues.id_informe_tecnico = response.data[0].id_informe_tecnico
          defaultValues.id_modelo_cabeza = response.data[0].id_modelo_cabeza
          defaultValues.id_tipo_cabeza = response.data[0].id_tipo_cabeza
          defaultValues.loftCabeza = response.data[0].loftCabeza
          defaultValues.distancia = response.data[0].distancia
          defaultValues.angSalida = response.data[0].angSalida
          defaultValues.spin = response.data[0].spin
          defaultValues.factImpacto = response.data[0].factImpacto
          defaultValues.velBola = response.data[0].velBola
          defaultValues.velPalo = response.data[0].velPalo
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getCabezaHierrosAct = () => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-actual-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdCabezaHierrosActual(response.data[0].id)
          defaultValues.id_cabeza_hierros_act = response.data[0].id
          defaultValues.distanciaAct = response.data[0].distancia
          defaultValues.angSalidaAct = response.data[0].angSalida
          defaultValues.spinAct = response.data[0].spin
          defaultValues.impactoAct = response.data[0].factImpacto
          defaultValues.bolaAct = response.data[0].velBola
          defaultValues.paloAct = response.data[0].velPalo
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function clearFields() {
    let defaultValues = {}
    defaultValues.id_cabeza_hierros = ""
    defaultValues.id_modelo_cabeza = ""
    defaultValues.id_tipo_cabeza = ""
    defaultValues.loftCabeza = ""
    defaultValues.distancia = ""
    defaultValues.angSalida = ""
    defaultValues.spin = ""
    defaultValues.factImpacto = ""
    defaultValues.velBola = ""
    defaultValues.velPalo = ""
    reset({ ...defaultValues })
  }

  useEffect(() => {
    getCabezaHierros()
    getHierrosequNuevo()
    getCabezaHierrosAct()
  }, [])

  const update = (id, idInf) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-ok`, {
        id: id,
        idInf: idInf
      })
      .then((res) => {
        getCabezaHierros()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const paloActual = (datos) => {
    if (idCabezaHierrosActual) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-actual-upd`, {
          data: datos
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          getCabezaHierrosAct()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-actual-ins`, {
          data: datos
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          getCabezaHierrosAct()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onSubmit = (data) => {
    if (idCabezaHierrosAct) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-upd`, {
          data: data
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          clearFields()
          getCabezaHierros()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-ins`, {
          data: data
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          clearFields()
          getCabezaHierros()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const deleteID = (id) => {
    Swal.fire({
      title: 'Estas seguro de eliminar este registro?',
      text: 'No podrás revertir esto.!',
      icon: 'Cuidado',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-del/${id}`)
          .then((res) => {
            Swal.fire({
              title: 'Eliminado!',
              icon: 'success',
              time: 1000,
            })
            getCabezaHierros()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  return (
    <Accordion.Item eventKey="4" className="mt-4">
      <Accordion.Button
        variant="link"
        className="w-100 d-flex justify-content-between accordion-button-info"
      >
        <span className="h6 mb-0 fw-bold">
          Selección de cabeza para hierros
        </span>
      </Accordion.Button>
      <Accordion.Body className="table-bording">
        <div className="row">
          {/* <h5 className="my-4">Cabeza Actual</h5>
          <Form onSubmit={handleSubmit(paloActual)}>
            <input type="hidden" {...register('id_cabeza_hierros_act')} />
            <input type="hidden" value={id} {...register('id_informe_tecnico')} />
            <table class="table table_class_informe">
              <thead>
                <tr>
                  <th scope="col">Modelo de Cabeza</th>
                  <th scope="col">Tipo de Cabeza</th>
                  <th scope="col">Loft de Cabeza (°)</th>
                  <th scope="col">Distancia (yds)</th>
                  <th scope="col">Ang. Salida (°)</th>
                  <th scope="col">Spin (rpm)</th>
                  <th scope="col">Fact Impacto (ind)</th>
                  <th scope="col">Vel. Bola (mph)</th>
                  <th scope="col">Vel Palo (mph)</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="seleccion">
                  <td className='textCenter'>{modeloCabeza}</td>
                  <td className='textCenter'>{tipoCabeza}</td>
                  <td className='textCenter'>{loftCabeza}</td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Distancia (yds)"
                      {...register('distanciaAct')}
                    />
                  </Form.Group></td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Ang. Salida (°)"
                      {...register('angSalidaAct')}
                    />
                  </Form.Group></td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Spin (rpm)"
                      {...register('spinAct')}
                    />
                  </Form.Group></td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Fact Impacto (ind)"
                      {...register('impactoAct')}
                    />
                  </Form.Group></td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Vel. Bola (mph)"
                      {...register('bolaAct')}
                    />
                  </Form.Group></td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Vel Palo (mph)"
                        {...register('paloAct')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <button type="submit" className="btn btn-success btn-sm mt-2">
                      Save
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form> */}
          <h5 className="my-4">Agregar cabeza</h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <table className="table form_table_class_informe">
              <thead>
                <tr>
                  <th scope="col">Modelo de Cabeza</th>
                  <th scope="col">Tipo de Cabeza</th>
                  <th scope="col">Loft de Cabeza (°)</th>
                  <th scope="col">Distancia (yds)</th>
                  <th scope="col">Ang. Salida (°)</th>
                  <th scope="col">Spin (rpm)</th>
                  <th scope="col">Fact Impacto (ind)</th>
                  <th scope="col">Vel. Bola (mph)</th>
                  <th scope="col">Vel Palo (mph)</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="hidden" {...register('id_cabeza_hierros')} />
                    <input type="hidden" value={id} {...register('id_informe_tecnico')} />
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_modelo_cabeza')}>
                        <option value="#">Sel. mod. Cabeza</option>
                        <ListName url="modelo-cabeza-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_tipo_cabeza')}>
                        <option value="#">Sel. tipo Cabeza</option>
                        <ListName url="tipos-cabeza-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="loftCabeza"
                        {...register('loftCabeza')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="distancia"
                        {...register('distancia')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="angSalida"
                        {...register('angSalida')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="spin"
                        {...register('spin')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="factImpacto"
                        {...register('factImpacto')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="velBola"
                        {...register('velBola')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="velPalo"
                        {...register('velPalo')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <button type="submit" className="btn btn-success btn-sm mt-2">
                      Guardar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
          {idCabezaHierros >= 0 && (
            <>
              <h5 className="my-4">Cabezas hierros</h5>
              <table className="table table_class_informe">
                <thead>
                  <tr>
                    <th scope="col" className='text-center'></th>
                    <th scope="col" className='text-center'>Modelo de Cabeza</th>
                    <th scope="col" className='text-center'>Tipo de Cabeza</th>
                    <th scope="col" className='text-center'>Loft de Cabeza (°)</th>
                    <th scope="col" className='text-center'>Distancia (yds)</th>
                    <th scope="col" className='text-center'>Ang. Salida (°)</th>
                    <th scope="col" className='text-center'>Spin (rpm)</th>
                    <th scope="col" className='text-center'>Fact Impacto (ind)</th>
                    <th scope="col" className='text-center'>Vel. Bola (mph)</th>
                    <th scope="col" className='text-center'>Vel Palo (mph)</th>
                    <th scope="col" className='text-center'></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(tablaCabezasHierros) && tablaCabezasHierros.map((val, key) => {
                    return <tr key={key}>
                      <td className="textCenter">
                        <input type="radio" value="" checked={val.ok} name="rad-cab-hierro" id="banNo" onClick={() => { update(val.id, val.id_informe_tecnico) }} />
                      </td>
                      <td className='text-center'>{val.nomModelo}</td>
                      <td className='text-center'>{val.namTipo}</td>
                      <td className='text-center'>{val.loftCabeza}</td>
                      <td className='text-center'>{val.distancia}</td>
                      <td className='text-center'>{val.angSalida}</td>
                      <td className='text-center'>{val.spin}</td>
                      <td className='text-center'>{val.factImpacto}</td>
                      <td className='text-center'>{val.velBola}</td>
                      <td className='text-center'>{val.velPalo}</td>
                      <td>
                        <button type="button" className="btn btn-primary btn-sm"
                          onClick={() => { getCabezaHierrosId(val.id) }}>
                          Actualizar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm mx-2"
                          onClick={() => { deleteID(val.id) }}>
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}
