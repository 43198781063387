import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Preloader from '../components/Preloader';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ProtectedRouteWithSidebar = ({ element: Component, allowedRoles, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  };

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  const tipoUsuario = localStorage.getItem('tipoUsuario');

  // Verifica si el usuario tiene acceso
  if (!allowedRoles.includes(tipoUsuario)) {
    return <Navigate to="/acceso-denegado" state={{ from: location }} replace />;
  }

  return (
    <>
      <Preloader show={!loaded} />
      <Sidebar onToggleMenu={setIsMenuVisible} role={tipoUsuario} />
      <main className={isMenuVisible ? 'content' : 'content-expanded'}>
        <Navbar />
        <Component /> {/* Asegúrate de que `Component` sea un componente React */}
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
  );
};

export default ProtectedRouteWithSidebar;
