import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import {
    Document,
    Page,
    View,
    Text,
    Image,
    StyleSheet,
    Font,
} from '@react-pdf/renderer'
import axios from 'axios'
import { PDFViewer } from '@react-pdf/renderer'
Font.register({ family: 'bebasNeue', src: 'font/BebasNeue-Regular.ttf' });


// Create Document Component
const DocumentoPDF = props => {
    const { id } = useParams();
    const [cliente, setCliente] = useState([]);
    const [tablaIdentActual, setTablaIdentActual] = useState([])
    const [pruebaCintas, setPruebaCintas] = useState([])
    const [tablaLoftHierros, setTablaLoftHierros] = useState([])
    const [varillaActual, setVarillaActual] = useState([])
    const [varillaActualDetalle, setVarillaActualDetalle] = useState([])
    const [tablaVarillasHierros, setTablaVarillasHierros] = useState([])
    const [driverActual, setDriverActual] = useState([])
    const [driverActualDetalle, setDriverActualDetalle] = useState([])
    const [tablaCabezaDriver, setTablaCabezaDriver] = useState([])
    const [tablaRecProfesional, setTablaRecProfesional] = useState([])
    const [advancedSet, setAdvancedSet] = useState("")
    const tipoUsuario = localStorage.getItem('tipoUsuario');

    // Cliente
    function getClientesFitting() {
        if (id) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/client-pdf-get`, {
                params: { id }
            })
                .then((response) => {
                    setCliente(response.data[0])
                })
                .catch((err) => {
                    console.log(err);
                })
        }

    }

    // Identificación equipo actual
    function getIdentActual() {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/identificacion-actual-all-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setTablaIdentActual(response.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Prueba de cintas
    function getTallaPalos() {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/talla-palos-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setPruebaCintas(response.data[0])
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Loft para hierros
    function getCabezaHierros() {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/cabeza-hierros-all-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setTablaLoftHierros(response.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Varilla actual
    function getVarillasEquActual() {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/varillas-equ-actual-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setVarillaActual(response.data[0])
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //varilla actual detalle
    const getVarillasHierrosAct = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/varillas-hierros-actual-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setVarillaActualDetalle(response.data[0])
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //varillas get
    function getVarillaHierros() {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/varillas-hierros-all-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setTablaVarillasHierros(response.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //Tabla driver
    function getCabezaDriver() {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/cabezas-driver-all-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setTablaCabezaDriver(response.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //Driver actual detalle
    const getCabezaDriverAct = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-actual-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setDriverActualDetalle(response.data[0])
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // driver actual
    const getDriverAct = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/driver-actual-get`, {
                params: { id },
            })
            .then((response) => {
                if (response != 'vacio') {
                    setDriverActual(response.data[0])
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Recomendación del profesional
    const getRecProfesional = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/recomendacion-profesional-get`, {
                params: { id },
            })
            .then((response) => {
                console.log("response: ", response)
                if (response) {
                    setTablaRecProfesional(response.data[0])
                    setAdvancedSet(response.data[0].detalle)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (id) {
            getClientesFitting();
            getIdentActual()
            getTallaPalos()
            getCabezaHierros()
            getVarillasEquActual()
            getVarillasHierrosAct()
            getVarillaHierros()
            getCabezaDriverAct()
            getDriverAct()
            getCabezaDriver()
            getRecProfesional()
        }
    }, [id]);


    const styles = StyleSheet.create({
        borderVerder: {
            border: "2px solid #008000 !important"
        },
        titleLeft: {
            fontSize: '20px',
            color: '#151430',
            marginBottom: "30px",
        },
        linea: {
            width: '100%',
            height: 20,
            backgroundColor: '#f3d98d',
            marginBottom: '10px',
        },
        lineaSub: {
            width: '100%',
            height: 20,
            backgroundColor: '#f3d98d',
            marginBottom: '50px',
        },
        pageNumbers: {
            position: 'absolute',
            marginTop: 20,
            bottom: 20,
            left: 0,
            right: 0,
            textAlign: 'center',
            fontSize: '8px',
            fontFamily: 'lato',
            color: "#151430"
        },
        page: {
            flexDirection: 'column',
            padding: '30px',
        },
        section: {
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 'calc(-1 * var(--bs-gutter-y))',
            marginRight: 'calc(-.5 * var(--bs-gutter-x))',
            marginLeft: 'calc(-.5 * var(--bs-gutter-x))',
            width: "100%"
        },
        col6: {
            flexDirection: "row",
            flexWrap: "wrap",
            width: "50%",
            fontSize: '10px',
            marginBottom: "-12px",
            textAlign: "left"
        },
        col3: {
            flexDirection: "row",
            flexWrap: "wrap",
            width: "33.33%",
            fontSize: '10px',
            marginBottom: "-12px",
            textAlign: "center"
        },
        col6Sub: {
            flexDirection: "row",
            flexWrap: "wrap",
            width: "50%",
            fontSize: '10px',
            marginBottom: "12px",
            textAlign: "left"
        },
        title: {
            marginTop: '10px',
            fontSize: '10px',
            color: '#151430',
            marginBottom: '10px',
        },
        titleSub: {
            marginTop: '10px',
            fontSize: '12px',
            color: '#151430',
            marginBottom: '10px',
        },
        text: {
            width: "100%",
            fontSize: '8px',
            marginBottom: "20px",
            color: "#151430"
        },
        textCenter: {
            width: "100%",
            fontSize: '8px',
            marginBottom: "20px",
            color: "#151430",
            textAlign: "center"
        },

        textInput: {
            width: "100%",
            fontSize: '8px',
            color: "#151430",
            border: "1px black solid",
            padding: "2px",
            marginRight: "10px"
        },
        textInputCenterTitle: {
            width: "100%",
            fontSize: '11px',
            color: "#151430",
            border: "1px black solid",
            padding: "2px",
            marginRight: "10px",
            textAlign: "center"
        },
        textInputCenter: {
            width: "100%",
            fontSize: '8px',
            color: "#151430",
            border: "1px black solid",
            padding: "2px",
            marginRight: "10px",
            marginBottom: "10px",
            textAlign: "center"
        },
        col12: {
            marginTop: "30px",
            width: "100%",
            fontSize: '12px'
        },
        row: {
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 'calc(-1 * var(--bs-gutter-y))',
            marginRight: 'calc(-.5 * var(--bs-gutter-x))',
            marginLeft: 'calc(-.5 * var(--bs-gutter-x))',
            width: "100%",
        },
        table: {
            width: '100%',
            marginTop: "10px",
            border: '1px solid #000',
        },
        cell: {
            width: '25%',
            border: '1px solid #000',
            padding: 8,
        },
        headerCell: {
            backgroundColor: '#ccc',
            fontWeight: 'bold',
        },
        cellText: {
            textAlign: 'center',
            fontSize: "8px"
        },
        centerCell: {
            width: '30%',
            border: '1px solid #000',
            padding: 8,
        },
        cell1: {
            width: '10%',
            border: '1px solid #000',
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
        },
        cellCintas: {
            width: '80%',
            border: '1px solid #000',
            padding: 10,
        },
        cell3: {
            width: '32.66%',
            border: '1px solid #000',
            padding: 70,
        },
        cel3: {
            width: '33.33%',
            border: '1px solid #000',
            padding: 8,
        },
        cell5: {
            width: '20%',
            border: '1px solid #000',
            padding: 8,
        },
        cell6: {
            width: '16.66%',
            border: '1px solid #000',
            padding: 8,
        },
        cell7: {
            width: '14.28%',
            border: '1px solid #000',
            padding: 8,
        },
        cuadroVerde: {
            backgroundColor: '#92d050',
            width: "70px",
            height: "15px",
            border: "2px solid black"
        },
        cuadroBlanco: {
            backgroundColor: "#fff",
            width: "70px",
            height: "15px",
            border: "2px solid black"
        },
        textareaContainer: {
            border: '2px solid black',
            padding: 8,
            marginBottom: 10,
        },
        textarea: {
            fontSize: 10,
        },
        circulo: {
            width: "100px",
            height: "100px",
            borderRadius: "50%",
        }
    });
    return (
        <>
            <Document>
                <PDFViewer style={{ width: '100%', height: '90vh' }}>
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <View style={styles.col6}>
                                    <Text style={styles.titleLeft}>Fitting Golf Colombia RC</Text>
                                </View>
                                <View style={styles.col6}>
                                    <Image src="taylor.png" style={{ width: 100 }} />
                                    <Image src="logo.jpeg" style={{ width: 30 }} />
                                    <Image src="ping.png" style={{ width: 70 }} />
                                </View>
                                <Text style={styles.linea}>
                                </Text>
                                <View style={styles.col6}>
                                    <Text style={styles.title}>INFORMACIÓN DEL CONTACTO</Text>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Nombre: </Text>
                                    </View>
                                    <View style={styles.col6}>D
                                        <Text style={styles.textInput}>{cliente.name}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Club: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.club}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Télefono: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.telefono}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Correo electrónico: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.email}</Text>
                                    </View>
                                </View>
                                <View style={styles.col6}>
                                    <Text style={styles.title}>INFORMACIÓN DEL JUGADOR</Text>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Fecha: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.created_at}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Handicap: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.handicap}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Edad / Mes cumpleaños: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.fecha_nacimiento}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Estatura: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.estatura}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Diestro/Zurdo: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.diestroZurdo}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Dist. De la muñeca al suelo: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.munecaSuelo}</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.text}>Tamaño Grip: </Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textInput}>{cliente.grip}</Text>
                                    </View>
                                </View>
                                <View style={styles.col12}>
                                    <Text style={styles.textInput}>Alcance: {cliente.alcance}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.titleSub}>IDENTIFICACIÓN DEL EQUIPO ACTUAL</Text>
                                </View>
                                <Text style={styles.lineaSub}>
                                </Text>
                                <View style={styles.table}>
                                    <View style={[styles.row, styles.headerCell]}>
                                        <View style={styles.cell5}><Text style={styles.cellText}>TIPO</Text></View>
                                        <View style={styles.cell5}><Text style={styles.cellText}>MARCA</Text></View>
                                        <View style={styles.cell5}><Text style={styles.cellText}>FLEX DE VARA</Text></View>
                                        <View style={styles.cell5}><Text style={styles.cellText}>PESO</Text></View>
                                        <View style={styles.cell5}><Text style={styles.cellText}>NÚMERO CABEZA / DESDE HASTA</Text></View>
                                    </View>
                                    {
                                        Array.isArray(tablaIdentActual) && tablaIdentActual.length > 0 &&
                                        tablaIdentActual.map((val, key) => {
                                            return <View key={key} style={styles.row}>
                                                <View style={styles.cell5}><Text style={styles.cellText}> {val.namTipPalo}</Text></View>
                                                <View style={styles.cell5}><Text style={styles.cellText}> {val.namMarca}</Text></View>
                                                <View style={styles.cell5}><Text style={styles.cellText}> {val.namFlex}</Text></View>
                                                <View style={styles.cell5}><Text style={styles.cellText}> {val.peso}</Text></View>
                                                <View style={styles.cell5}><Text style={styles.cellText}> {val.desde > 0 ? val.desde + "-" + val.hasta : val.numero_cabeza}</Text></View>
                                            </View>
                                        })
                                    }
                                </View>
                            </View>
                        </Page>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <View style={styles.col6}>
                                    <Text style={styles.titleSub}>SELECCIÓN DE LIE PARA HIERROS</Text>
                                </View>
                                <View style={styles.col6Sub}>
                                    <Image src="taylor.png" style={{ width: 100 }} />
                                    <Image src="logo.jpeg" style={{ width: 30 }} />
                                    <Image src="ping.png" style={{ width: 70 }} />
                                </View>
                                <Text style={styles.linea}>
                                </Text>
                                <View style={styles.col12}>
                                    <Text style={styles.textInputCenter}>PRUEBA DE CINTA</Text>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.cell1}><Text style={{ ...styles.cellText }}>{pruebaCintas.primera_color}</Text></View>
                                    <View style={styles.cellCintas}>{
                                        pruebaCintas.primera_imagen && pruebaCintas.primera_imagen.length > 0 && <Image src={`${process.env.REACT_APP_BASE_URL}/imagenes-talla-palos/${pruebaCintas.primera_imagen}`} style={{ width: 400 }} />
                                    }</View>
                                    <View style={styles.cell1}><Text style={styles.cellText}>{pruebaCintas.primera_seleccion_2}</Text></View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.cell1}><Text style={styles.cellText}>{pruebaCintas.segunda_color}</Text></View>
                                    <View style={styles.cellCintas}>{
                                        pruebaCintas.segunda_imagen && pruebaCintas.segunda_imagen.length > 0 && <Image src={`${process.env.REACT_APP_BASE_URL}/imagenes-talla-palos/${pruebaCintas.segunda_imagen}`} style={{ width: 400 }} />
                                    }</View>
                                    <View style={styles.cell1}><Text style={styles.cellText}>{pruebaCintas.segunda_seleccion}</Text></View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.cell1}><Text style={styles.cellText}>{pruebaCintas.tercera_color}</Text></View>
                                    <View style={styles.cellCintas}>{
                                        pruebaCintas.tercera_imagen && pruebaCintas.tercera_imagen.length > 0 && <Image src={`${process.env.REACT_APP_BASE_URL}/imagenes-talla-palos/${pruebaCintas.tercera_imagen}`} style={{ width: 400 }} />
                                    }</View>
                                    <View style={styles.cell1}><Text style={styles.cellText}>{pruebaCintas.tercera_seleccion}</Text></View>
                                </View>
                                <View style={styles.col12}>
                                    <Text style={styles.textInputCenter}>RECOMENDACIÓN</Text>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.cell5}><Text style={styles.cellText}>CÓDIGO DE COLOR EN PING</Text></View>
                                    <View style={styles.cell5}><Text style={styles.cellText}>LIE FLAT</Text></View>
                                    <View style={styles.cell5}><Text style={styles.cellText}>LIE ESTANDAR</Text></View>
                                    <View style={styles.cell5}><Text style={styles.cellText}>LIE UP</Text></View>
                                    <View style={styles.cell5}><Text style={styles.cellText}>TAMAÑO VARILLA</Text></View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.cell5}><Text style={styles.cellText}>{pruebaCintas.color}</Text></View>
                                    <View style={styles.cell5}><Text style={styles.cellText}>{pruebaCintas.lie_flat}</Text></View>
                                    <View style={styles.cell5}><Text style={styles.cellText}>{pruebaCintas.lie_estandar}</Text></View>
                                    <View style={styles.cell5}><Text style={styles.cellText}>{pruebaCintas.lie_up}</Text></View>
                                    <View style={styles.cell5}><Text style={styles.cellText}>{pruebaCintas.long_vara}</Text></View>
                                </View>
                            </View>
                        </Page>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <View style={styles.col6}>
                                    <Text style={styles.titleSub}>SELECCIÓN DE LOFT PARA HIERROS</Text>
                                </View>
                                <View style={styles.col6Sub}>
                                    <Image src="taylor.png" style={{ width: 100 }} />
                                    <Image src="logo.jpeg" style={{ width: 30 }} />
                                    <Image src="ping.png" style={{ width: 70 }} />
                                </View>
                                <Text style={styles.linea}>
                                </Text>
                                <View style={styles.table}>
                                    <View style={[styles.row, styles.headerCell]}>
                                        <View style={styles.cell7}><Text style={styles.cellText}>REFERENCIA</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>DISTANCIA (YDS)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>ANG. SALIDA (°)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>SPIN (RPM)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>FACT. IMPACTO (IND)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>VEL. BOLA</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>VEL. PALO</Text></View>
                                    </View>
                                </View>

                                {
                                    Array.isArray(tablaLoftHierros) && tablaLoftHierros.length > 0 &&
                                    tablaLoftHierros.map((val, key) => {
                                        return <View key={key} style={{ ...styles.row, ...(val.ok === 1 && styles.borderVerder) }}>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.nomModelo}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.distancia}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.angSalida}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.spin}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.factImpacto}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.velBola}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.velPalo}</Text></View>
                                        </View>
                                    })
                                }
                                <View style={styles.col12}>
                                    <Text style={styles.textInputCenter}>RECOMENDACIÓN DE EQUIPO TEORICA</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.text}></Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.text}>REFERENCIA</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.text}>NÚMERO</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.text}>HIERROS</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.textInputCenter}>P770 CUSTOMIZED P790</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.textInputCenter}>4; 5 ; 6 ; 7 ; 8 ; 9 ; Pw.</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.text}>PALOS LARGOS</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.textInputCenter}>STEALTH PLUS</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.textInputCenter}>Driver ; W3 ; HYB3;</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.text}>WEDGES</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.textInputCenter}>MG3</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.textInputCenter}>50º ; 54º ; 58º</Text>
                                </View>
                                <View style={styles.col12}>
                                    <Text style={styles.textInputCenter}>TENDENCIA DE TRAYECTORIA CON LA RECOMENDACIÓN REALIZADA PARA EL JUEGO CORTO (HIERROS)</Text>
                                </View>
                                <View style={styles.col6}>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MÁS ALTURA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroVerde}></Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MENOR ALTURA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroBlanco}></Text>
                                    </View>
                                </View>
                                <View style={styles.col6}>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MÁS ALTURA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroVerde}></Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MENOR DISTANCIA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroBlanco}></Text>
                                    </View>
                                </View>
                                <View style={styles.col12}>
                                    <Text style={styles.textInputCenter}>TENDENCIA DE TRAYECTORIA CON LA RECOMENDACIÓN REALIZADA PARA EL JUEGO LARGO (HIERROS)</Text>
                                </View>
                                <View style={styles.col6}>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MÁS ALTURA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroBlanco}></Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MENOR ALTURA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroVerde}></Text>
                                    </View>
                                </View>
                                <View style={styles.col6}>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MÁS ALTURA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroBlanco}></Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MENOR DISTANCIA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroVerde}></Text>
                                    </View>
                                </View>
                                <View style={styles.col12}>
                                    <Text style={styles.textInputCenter}>TENDENCIA DE TRAYECTORIA CON LA RECOMENDACIÓN REALIZADA PARA LOS WEDGES</Text>
                                </View>
                                <View style={styles.col6}>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MAYOR ALTURA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroVerde}></Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MAYOR DISTANCIA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroVerde}></Text>
                                    </View>
                                </View>
                                <View style={styles.col6}>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MENOR ALTURA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroBlanco}></Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.textCenter}>MENOR DISTANCIA</Text>
                                    </View>
                                    <View style={styles.col6}>
                                        <Text style={styles.cuadroVerde}></Text>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <View style={styles.col6}>
                                    <Text style={styles.titleSub}>SELECCIÓN DE VARILLA</Text>
                                </View>
                                <View style={styles.col6Sub}>
                                    <Image src="taylor.png" style={{ width: 100 }} />
                                    <Image src="logo.jpeg" style={{ width: 30 }} />
                                    <Image src="ping.png" style={{ width: 70 }} />
                                </View>
                                <Text style={styles.linea}>
                                </Text>
                                <View style={styles.table}>
                                    <View style={[styles.row]}>
                                        <View style={styles.cell7}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>DISTANCIA (YDS)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>ANG. SALIDA (°)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>SPIN (RPM)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>FACT. IMPACTO (IND)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>VEL. BOLA</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>VEL. PALO</Text></View>
                                    </View>
                                    <View style={[styles.row, styles.headerCell]}>
                                        <View style={styles.cell7}><Text style={styles.cellText}>VARA ACTUAL</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{varillaActualDetalle.distancia}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{varillaActualDetalle.angSalida}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{varillaActualDetalle.spin}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{varillaActualDetalle.factImpacto}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{varillaActualDetalle.velBola}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{varillaActualDetalle.velPalo}</Text></View>
                                    </View>
                                </View>
                                {
                                    Array.isArray(tablaVarillasHierros) && tablaVarillasHierros.length > 0 &&
                                    tablaVarillasHierros.map((val, key) => {
                                        return <View key={key} style={{ ...styles.row, ...(val.ok === 1 && styles.borderVerder) }}>
                                            <View style={styles.cell7}><Text style={styles.cellText}>SWING {key + 1}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.distancia}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.angSalida}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.spin}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.factImpacto}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.velBola}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.velPalo}</Text></View>
                                        </View>
                                    })
                                }
                                <View style={styles.col12}>
                                    <Text style={styles.textInputCenter}>CARACTERISTICAS TÉCNICAS DE LA VARILLA EN PRUEBA</Text>
                                </View>
                                <View style={styles.table}>
                                    <View style={[styles.row]}>
                                        <View style={styles.cell6}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>REFERENCIA VARA</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>PESO gr</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>FLEX</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>MATERIAL</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>TRAYECTORIA</Text></View>
                                    </View>
                                    <View style={[styles.row, styles.headerCell]}>
                                        <View style={styles.cell6}><Text style={styles.cellText}>VARA ACTUAL</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{varillaActual.nomModelo}</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{varillaActual.peso}</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{varillaActual.namFlex}</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{varillaActual.namMaterial}</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}></Text></View> {/* preguntar  */}
                                    </View>
                                    {
                                        Array.isArray(tablaVarillasHierros) && tablaVarillasHierros.length > 0 &&
                                        tablaVarillasHierros.map((val, key) => {
                                            return <View style={{ ...styles.row, ...(val.ok === 1 && styles.borderVerder) }}>
                                                <View style={styles.cell6}><Text style={styles.cellText}>SWING {key + 1}</Text></View>
                                                <View style={styles.cell6}><Text style={styles.cellText}>{val.nomModelo}</Text></View>
                                                <View style={styles.cell6}><Text style={styles.cellText}>{val.peso}</Text></View>
                                                <View style={styles.cell6}><Text style={styles.cellText}>{val.namFlex}</Text></View>
                                                <View style={styles.cell6}><Text style={styles.cellText}>{val.nomMaterial}</Text></View>
                                                <View style={styles.cell6}><Text style={styles.cellText}>{val.trayectoria}</Text></View> {/* preguntar  */}
                                            </View>
                                        })
                                    }
                                </View>
                            </View>
                        </Page>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <View style={styles.col6}>
                                    <Text style={styles.titleSub}>SELECCIÓN DE DRIVER</Text>
                                </View>
                                <View style={styles.col6Sub}>
                                    <Image src="taylor.png" style={{ width: 100 }} />
                                    <Image src="logo.jpeg" style={{ width: 30 }} />
                                    <Image src="ping.png" style={{ width: 70 }} />
                                </View>
                                <Text style={styles.linea}>
                                </Text>
                                <View style={styles.table}>
                                    <View style={[styles.row]}>
                                        <View style={styles.cell7}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>DISTANCIA (YDS)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>ANG. SALIDA (°)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>SPIN (RPM)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>FACT. IMPACTO (IND)</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>VEL. BOLA</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>VEL. PALO</Text></View>
                                    </View>
                                    <View style={[styles.row, styles.headerCell]}>
                                        <View style={styles.cell7}><Text style={styles.cellText}>VARA ACTUAL</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{driverActualDetalle.distancia}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{driverActualDetalle.angSalida}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{driverActualDetalle.spin}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{driverActualDetalle.factImpacto}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{driverActualDetalle.velBola}</Text></View>
                                        <View style={styles.cell7}><Text style={styles.cellText}>{driverActualDetalle.velPalo}</Text></View>
                                    </View>
                                </View>
                                {
                                    Array.isArray(tablaCabezaDriver) && tablaCabezaDriver.length > 0 &&
                                    tablaCabezaDriver.map((val, key) => {
                                        return <View key={key} style={{ ...styles.row, ...(val.ok === 1 && styles.borderVerder) }}>
                                            <View style={styles.cell7}><Text style={styles.cellText}>SWING {key + 1}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.distancia}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.angSalida}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.spin}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.factImpacto}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.velBola}</Text></View>
                                            <View style={styles.cell7}><Text style={styles.cellText}>{val.velPalo}</Text></View>
                                        </View>
                                    })
                                }
                                <View style={styles.col12}>
                                    <Text style={styles.textInputCenter}>CARACTERISTICAS TÉCNICAS DE LA VARILLA EN PRUEBA</Text>
                                </View>
                                <View style={styles.table}>
                                    <View style={[styles.row]}>
                                        <View style={styles.cell6}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>REFERENCIA VARA</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>PESO gr</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>FLEX</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>MATERIAL</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>TRAYECTORIA</Text></View>
                                    </View>
                                    <View style={[styles.row, styles.headerCell]}>
                                        <View style={styles.cell6}><Text style={styles.cellText}>VAR ACTUAL</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{driverActual.nomModVarilla} / {driverActual.nomModelo}</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{driverActual.peso}</Text></View>{/* preguntar */}
                                        <View style={styles.cell6}><Text style={styles.cellText}>{driverActual.nameFlex}</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{driverActual.loftCabeza}</Text></View>{/* preguntar */}
                                        <View style={styles.cell6}><Text style={styles.cellText}>STRAIGHT</Text></View>{/* preguntar */}
                                    </View>
                                </View>
                                {[1, 2, 3, 4].map((rowIndex) => (
                                    <View key={rowIndex} style={styles.row}>
                                        <View style={styles.cell6}><Text style={styles.cellText}>SWING {rowIndex}</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{rowIndex}, SWING 2</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{rowIndex}, SWING 3</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{rowIndex}, SWING 4</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{rowIndex}, SWING 5</Text></View>
                                        <View style={styles.cell6}><Text style={styles.cellText}>{rowIndex}, SWING 5</Text></View>
                                    </View>
                                ))}
                            </View>
                        </Page>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <View style={styles.col6}>
                                    <Text style={styles.titleSub}>SELECCIÓN DE DRIVER</Text>
                                </View>
                                <View style={styles.col6Sub}>
                                    <Image src="taylor.png" style={{ width: 100 }} />
                                    <Image src="logo.jpeg" style={{ width: 30 }} />
                                    <Image src="ping.png" style={{ width: 70 }} />
                                </View>
                                <Text style={styles.linea}>
                                </Text>
                                <View style={styles.textareaContainer}>
                                    <Text style={styles.textarea}>
                                        {advancedSet}
                                    </Text>
                                </View>
                                <View style={styles.col6}>
                                    <Text style={styles.titleSub}>ADVANCED SET MAKE UP</Text>
                                </View>
                                <View style={styles.col6Sub}>
                                    <Image src="taylor.png" style={{ width: 100 }} />
                                    <Image src="logo.jpeg" style={{ width: 30 }} />
                                    <Image src="ping.png" style={{ width: 70 }} />
                                </View>
                                <Text style={styles.linea}>
                                </Text>
                                <View style={styles.table}>
                                    <View style={[styles.row, styles.headerCell]}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>CLUB</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>LOFT (°)</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>CARRY GARANTIZADO</Text></View>
                                    </View>
                                </View>
                                {
                                    tablaRecProfesional.loftDriver?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>DRIVER</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.loftDriver}</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.loftDriver}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_maderas?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>W3</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>15</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_maderas}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_maderas2?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>W5</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>18</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_maderas2}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_maderas3?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>W7</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>21</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_maderas3}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_maderas4?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>W9</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>27</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_maderas4}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hibridos?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>HYB2</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>17</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hibridos}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hibridos2?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>HYB3</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>19</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hibridos2}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hibridos3?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>HYB4</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>22</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hibridos3}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hibridos4?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>HYB5</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>26</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hibridos4}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hibridos5?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>HYB6</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>30</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hibridos5}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_driving?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>ID3</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>15</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_driving}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_driving2?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>ID4</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>18</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_driving2}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_driving3?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>ID5</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>21</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_driving3}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_driving4?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>ID6</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>27</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_driving4}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hierros?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>i3</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hierros}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hierros4?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>i4</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hierros4}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hierros5?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>i5</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hierros5}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hierros6?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>i6</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hierros6}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hierros7?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>i7</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hierros7}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hierros8?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>i8</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hierros8}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hierros9?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>i9</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hierros9}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_hierrosPW?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>PW</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}></Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_hierrosPW}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>PW 43°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>43</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges2?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>PW 48°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>48</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges2}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges3?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>AW 50°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>50</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges3}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges4?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>AW 52°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>52</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges4}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges5?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>SW 54°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>54</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges5}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges6?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>SW 56°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>56</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges6}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges7?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>LW 58°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>58</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges7}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges8?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>LW 60°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>60</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges8}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges9?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>LW 62°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>62</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges9}</Text></View>
                                    </View>
                                    )
                                }
                                {
                                    tablaRecProfesional.numero_wedges10?.length > 0 && (<View style={styles.row}>
                                        <View style={styles.cel3}><Text style={styles.cellText}>LW 64°</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>64</Text></View>
                                        <View style={styles.cel3}><Text style={styles.cellText}>{tablaRecProfesional.numero_wedges10}</Text></View>
                                    </View>
                                    )
                                }

                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </Document>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            {tipoUsuario === 'admin' && (
                <Link to={`/fitting/fitting-details/${id}`}>
                    <button type="button" className="btn btn-primary">Volver</button>
                </Link>
            )}
            {tipoUsuario === 'user' && (
                <Link to={`/fitting-client`}>
                    <button type="button" className="btn btn-primary">Volver</button>
                </Link>
            )}
            </div>

        </>
    )

}
export default DocumentoPDF;  
