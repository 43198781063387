import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col, Card, Spinner } from '@themesberg/react-bootstrap';

const ProductCard = ({ image, title, description, price, link }) => (
  <Card className="mb-3 shadow-sm" style={{ minHeight: '150px', border: '1px solid #ddd' }}>
    <Card.Img variant="top" src={image} />
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Card.Text>{description}</Card.Text>
      <h5>{price}</h5>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <button type="button" className="btn btn-primary btn-sm">Ver</button>
      </a>
    </Card.Body>
  </Card>
);

const ProductPage = () => {
  const { categoryId, idFitting } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/driver-clientes`, {
          params: { categoriaId: categoryId, idFitting: idFitting }
        });
        console.log("categoryId: ",categoryId,"idFitting: ", idFitting)
        setProducts(response.data);
        setLoading(false);
      } catch (err) {
        setError('Hubo un error al obtener los productos.');
        console.error(err);
        setLoading(false);
      }
    };

    if (categoryId && idFitting) {
      fetchProducts();
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-5">
        <Container>
          <h1>Productos de la categoría </h1>

          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          ) : error ? (
            <p>{error}</p>
          ) : products.length > 0 ? (
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body>
                <Row>
                  {products.map(product => (
                    <Col key={product.id} sm={6} md={4} lg={3} className="mb-4">
                      <ProductCard
                        image={product.image}
                        title={product.name}
                        description={product.description}
                        price={product.price}
                        link={`https://rccolombiangolf.com/producto/g425-max/?attribute_pa_flex=extra-stiff&attribute_pa_manejo=diestro&attribute_pa_talla=estandar&attribute_pa_loft=10a`}
                      />
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          ) : (
            <p>No se encontraron productos para esta categoría.</p>
          )}
        </Container>
      </div>
    </>
  );
};

export default ProductPage;
