import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "@themesberg/react-bootstrap";
import { Carousel } from "react-bootstrap";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"; // Importa las flechas de FontAwesome
import "../style/clients.css";

const categories = [
  { id: 1, name: 'Driver', image: 'https://rccolombiangolf.com/wp-content/uploads/2022/02/EPIC-MAX-LS.png' },
  { id: 2, name: 'Madera', image: 'https://rccolombiangolf.com/wp-content/uploads/2021/12/GLE-MADERA.jpg' },
  { id: 3, name: 'Hibirdo', image: 'https://rccolombiangolf.com/wp-content/uploads/2022/02/G425.jpg' },
  { id: 4, name: 'Hierros', image: 'https://rccolombiangolf.com/wp-content/uploads/2022/02/P770-IRON.jpg' },
  { id: 5, name: 'Wedges', image: 'https://rccolombiangolf.com/wp-content/uploads/2021/12/MILLED-GRIND-3.jpg' },
  { id: 37, name: 'Putts', image: 'https://rccolombiangolf.com/wp-content/uploads/2021/12/HEPPLER-TOMCAT-14.jpg' },
];

const chunkCategories = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const CategorySlider = () => {
  const [idFitting, setIdFitting] = useState([]);
  const navigate = useNavigate();
  const handleCategoryClick = (id,idFitting) => {
    navigate(`/client/product/${id}/${idFitting}`);
  };
  const storedIdClient = localStorage.getItem('id');
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/informe-tecnico-cliente`, {
          params: { idClient: storedIdClient }
        });

        if (response.data && response.data.length > 0) {
          setIdFitting(response.data[0].id); 
        } else {
          console.error("No data received");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchProducts();
  }, [storedIdClient]);
  

  const categoryChunks = chunkCategories(categories, 4);

  return (
    <Carousel
      interval={5000}
      fade
      indicators={true}
      prevIcon={<FontAwesomeIcon icon={faArrowLeft} className="custom-arrow" />} 
      nextIcon={<FontAwesomeIcon icon={faArrowRight} className="custom-arrow" />}
      className="custom-carousel"
    >
      {categoryChunks.map((categoryChunk, index) => (
        <Carousel.Item key={index}>
          <Row className="justify-content-center">
            {categoryChunk.map((category) => (
              <Col md={3} key={category.id}>
                <Card className="text-center category-card" onClick={() => handleCategoryClick(category.id,idFitting)}>
                  <Card.Img variant="top" src={category.image} alt={category.name} className="category-image" />
                  <Card.Body>
                    <Card.Title className="category-title">{category.name}</Card.Title>
                    <Button variant="primary" className="category-button">Ver </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-5">
        <Container>
          <h1 className="text-center display-4 mb-4">Product Categories</h1>
          <CategorySlider />
        </Container>
      </div>
    </>
  );
};