import React, { useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { Link } from 'react-router-dom'
import axios from 'axios'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{' '}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{' '}
      </label>
    </div>
  ),
})

let data = []
function clients() {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/varas-get`)
    .then((response) => {
      data = response.data
    })
    .catch((err) => {
      console.log(err)
    })
}

clients()

const { SearchBar } = Search
class Varas extends React.Component {
  
  render() {
    return (
      <>
        <ToolkitProvider
          data={data}
          keyField="id"
          columns={[
            {
              dataField: 'id',
              text: 'ID',
              sort: true,
            },
            {
              dataField: 'tipoPalo',
              text: 'Tipo Palo',
              sort: true,
            },
            {
              dataField: 'marca',
              text: 'Marca',
              sort: true,
            },
            {
              dataField: 'material',
              text: 'Material',
              sort: true,
            },
            {
              dataField: 'fabrica',
              text: 'Fabrica',
              sort: true,
            },
            {
              dataField: 'modelo',
              text: 'Modelo',
              sort: true,
            },
            {
              dataField: 'flexes',
              text: 'Flexes',
              sort: true,
            },
            {
              dataField: 'peso',
              text: 'Peso',
              sort: true,
            },
            {
              dataField: 'launch',
              text: 'Launch',
              sort: true,
            },
            {
              dataField: 'torque',
              text: 'Torque',
              sort: true,
            },
            {
              dataField: 'id',
              text: 'Action',
              formatter: (cellContent, row) => {
                return (
                  <React.Fragment>
                    <Link to={`/varas/varas-details/${row.id}`}>
                      <button type="button" className="btn btn-primary btn-sm">
                        Ver
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm mx-2"
                    >
                      Borrar
                    </button>
                  </React.Fragment>
                )
              },
              hidden: false,
              checked: true,
              editable: false,
            },
          ]}
          search
        >
          {(props) => (
            <div className="table_class py-4">
              <Link to="/varas/varas-new">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  style={{ width: '100px', marginBottom: '30px' }}
                >
                  Nuevo
                </button>
              </Link>
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-1 pb-4"
              >
                <label>
                  Search:
                  <SearchBar
                    className="form-control-sm"
                    placeholder=""
                    {...props.searchProps}
                  />
                </label>
              </div>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
      </>
    )
  }
}

export default Varas
