import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom';
import { ListName } from '../../../components/fitting-components/Functions/functions-listas-select'
import { Col, Row, Form, Button, InputGroup } from '@themesberg/react-bootstrap'

const LoftHierrosDetails = (props) => {
  const { id } = props.match.params;
  const isUpdating = !!id;

  useEffect(() => {
    getLoftHierros();
  }, [id]);

  const getLoftHierros = () => {
    let defaultValues = {};
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/loft-hierros-get-id`, {
        params: { id },
      })
      .then((response) => {
        if (response !== 'vacio') {
          defaultValues.set = response.data[0].sett;
          defaultValues.loft = response.data[0].loft;
          defaultValues.id_modeloscabeza = response.data[0].id_modeloscabeza;
          reset({ ...defaultValues });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' });

  const onSubmit = (data) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/upd-loft-hierros`, { data })
      .then((res) => {
        Swal.fire({
          html: '<i>Process complete!</i>',
          icon: 'success',
          timer: 2000,
        });
        window.location = `#/loft-hierros`
      })
      .catch((err) => {
        console.log(err);
      });

  };

  return (
    <article className="table_class">
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-md-0">
          <br />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input type='hidden' {...register('id')} value={id} />
            <Row>
              <h5 className="my-4">Loft de hierro</h5>
              <Col md={6} className="mb-3">
                <Form.Group id="set">
                  <Form.Label>Set</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el Set"
                    {...register('set', { required: true })}
                  />
                  {errors.nombre?.type === 'required' && (
                    <small className="textRequired">
                      Este campo es requerido
                    </small>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="Loft">
                  <Form.Label>Loft</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el Loft"
                    {...register('loft')}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Modelo Cabeza</Form.Label>
                  <Form.Select defaultValue="0" {...register('id_modeloscabeza')}>
                    <ListName url="mdc-hierros-get" />
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <div className="mt-3">
              <Button variant={"success"} type="submit">
                {"Actualizar"}
              </Button>
              <React.Fragment>
                <Link to={`/loft-hierros/`}>
                  <button type="button" className="btn btn-primary" style={{ marginLeft: '1rem' }} >
                    Volver
                  </button>
                </Link>
              </React.Fragment>
            </div>
          </Form>
        </Col>
      </Row>
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-md-0"></Col>
      </Row>
    </article>
  );
};

export default LoftHierrosDetails;