import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Accordion, Form, Button } from '@themesberg/react-bootstrap'
import { ListName } from './Functions/functions-listas-select'
import { useForm } from 'react-hook-form'
import Pdf from './Functions/functions-modal-imagen'

export default (props) => {
  const id = props.id

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' })

  const [idTallaPalos, setIdTallaPalos] = useState(0)
  const [img1, setImg1] = useState('')
  const [img2, setImg2] = useState('')
  const [img3, setImg3] = useState('')
  const [imga1, setImga1] = useState('')
  const [imga2, setImga2] = useState('')
  const [imga3, setImga3] = useState('')
  const [lieFlatSel, setLieFlatSel] = useState('')
  const [lieEstandarSel, setLieEstandarSel] = useState('')
  const [lieUpSel, setLieUpSel] = useState('')
  const [color, setColor] = useState('')
  const [colorUno, setColorUno] = useState('')
  const [colorDos, setColorDos] = useState('')
  const [colorTres, setColorTres] = useState('')

  function getTallaPalos() {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/talla-palos-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdTallaPalos(response.data[0].idRec)
          setImga1(response.data[0].primera_imagen)
          setImga2(response.data[0].segunda_imagen)
          setImga3(response.data[0].tercera_imagen)
          setImg1(response.data[0].primera_imagen)
          setImg2(response.data[0].segunda_imagen)
          setImg3(response.data[0].tercera_imagen)
          defaultValues.id = response.data[0].id
          defaultValues.idRec = response.data[0].idRec
          defaultValues.idLie = response.data[0].idLie
          defaultValues.principal = response.data[0].principal
          defaultValues.primera_color = response.data[0].primera_color
          defaultValues.primera_tendencia = response.data[0].primera_tendencia
          defaultValues.primera_seleccion_2 = response.data[0].primera_seleccion_2
          defaultValues.principal = response.data[0].principal
          defaultValues.segunda_color = response.data[0].segunda_color
          defaultValues.segunda_tendencia = response.data[0].segunda_tendencia
          defaultValues.segunda_seleccion_2 = response.data[0].segunda_seleccion_2
          defaultValues.principal = response.data[0].principal
          defaultValues.tercera_color = response.data[0].tercera_color
          defaultValues.tercera_tendencia = response.data[0].tercera_tendencia
          defaultValues.tercera_seleccion_2 = response.data[0].tercera_seleccion_2
          defaultValues.color = response.data[0].color
          setColor(response.data[0].color)
          defaultValues.lie_flat = response.data[0].lie_flat
          defaultValues.lie_estandar = response.data[0].lie_estandar
          defaultValues.lie_up = response.data[0].lie_up
          defaultValues.id_long_vara = response.data[0].id_long_vara
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getTallaPalos()
  }, [])
  const imagen1 = (e) => {
    setImg1(e.target.files[0])
  }
  const imagen2 = (e) => {
    setImg2(e.target.files[0])
  }
  const imagen3 = (e) => {
    setImg3(e.target.files[0])
  }

  const lieFlat = (val) => {
    if (val.length > 0) {
      setLieEstandarSel(1)
      setLieUpSel(1)
    } else {
      setLieEstandarSel('')
      setLieUpSel('')
    }
  }

  const lieEstandar = (val) => {
    if (val.length > 0) {
      setLieFlatSel(1)
      setLieUpSel(1)
    } else {
      setLieFlatSel('')
      setLieUpSel('')
    }
  }

  const lieUp = (val) => {
    if (val.length > 0) {
      setLieFlatSel(1)
      setLieEstandarSel(1)
    } else {
      setLieFlatSel('')
      setLieEstandarSel('')
    }
  }

  const selColor = (val) => {
    if (val == "primera") {
      setColor(colorUno)
    } else if (val == "segunda") {
      setColor(colorDos)
    } else if (val == "tercera") {
      setColor(colorTres)
    }
  }
  const onSubmit = (data) => {
    let ima1 = ''
    let ima2 = ''
    let ima3 = ''
    const formData = new FormData()
    formData.append('image', img1)
    formData.append('image2', img2)
    formData.append('image3', img3)
    formData.append('id', id)
    formData.append('folder', 'imagenes-talla-palos')
    fetch(`${process.env.REACT_APP_BASE_URL}/imagenes-ins`, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res == 'error') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              'the document size is too large... maximum size allowed is 2MB. !',
          })
        } else {
          let ima1 = '';
          let ima2 = '';
          let ima3 = '';
          if (Array.isArray(res)) {
            for (let i = 0; i < res.length; i++) {
              const currentItem = res[i];
          
              if (currentItem !== null && currentItem !== undefined) {
                if (currentItem.img1 !== null && currentItem.img1 !== undefined) {
                  ima1 = currentItem.img1;
                }
          
                if (currentItem.img2 !== null && currentItem.img2 !== undefined) {
                  ima2 = `${currentItem.img2}`;
                }
          
                if (currentItem.img3 !== null && currentItem.img3 !== undefined) {
                  ima3 = currentItem.img3;
                }
              }
            }
          }
          const endpoint = idTallaPalos > 0 ? 'talla-palos-upd' : 'talla-palos-ins';

          axios
            .request({
              method: idTallaPalos > 0 ? 'put' : 'post',
              url: `${process.env.REACT_APP_BASE_URL}/${endpoint}`,
              data: {
                data: data,
                color: color,
                img1: ima1,
                img2: ima2,
                img3: ima3,
              },
            })
            .then((response) => {
              Swal.fire({
                html: '<i>Process complete!</i>',
                icon: 'success',
                timer: 1000,
              });
              window.location.reload()
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            'Error del servidor',
        })
        console.log(err)
      })
  }

  const deleteID = (id, num, imagen) => {
    Swal.fire({
      title: 'Estas seguro de eliminar este registro?',
      text: 'No podrás revertir esto.!',
      icon: 'Cuidado',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`${process.env.REACT_APP_BASE_URL}/prueba-cintas-upd`, {
            id: id,
            num: num,
            imagen: imagen,
          })
          .then((res) => {
            Swal.fire({
              title: 'Eliminado!',
              icon: 'success',
              time: 1000,
            })
            getTallaPalos()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }

  return (
    <Accordion.Item eventKey="3" className="accordion-item-info mt-4">
      <Accordion.Button
        variant="link"
        className="w-100 d-flex justify-content-between accordion-button-info"
      >
        <span className="h6 mb-0 fw-bold">Talla de palos</span>
      </Accordion.Button>
      <Accordion.Body className="table-bording">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value={id} {...register('idInforme')} />
          <input type="hidden" {...register('idRec')} />
          <input type="hidden" {...register('idLie')} />
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" colSpan={'3'}>
                    Prueba de cintas
                  </th>
                  <th scope="col">
                    Tendencia
                  </th>
                  <th scope="col">
                    Trayectoria
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr><td className="textCenter">
                  <input
                    type="radio"
                    className="mt-3"
                    value="primera"
                    name="ban"
                    {...register('principal')}
                    onClick={(e) => selColor(e.target.value)}
                  />
                </td>
                  <td>
                    <Form.Group>
                      <Form.Select
                        defaultValue="0"
                        {...register('primera_color')}
                        onChange={(e) => setColorUno(e.target.value)}
                      >
                        <option value="0">Seleccione</option>
                        <option value="Gold">Gold</option>
                        <option value="Brown">Brown</option>
                        <option value="Orange">Orange</option>
                        <option value="Red">Red</option>
                        <option value="Black">Black</option>
                        <option value="Blue">Blue</option>
                        <option value="Green">Green</option>
                        <option value="White">White</option>
                        <option value="Silver">Silver</option>
                        <option value="Maroon">Maroon</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    {imga1 !== null && imga1 !== undefined && imga1.length > 4 ? (
                      <Pdf
                        attach={1}
                        typeDoc={2}
                        doc={img1}
                        taxId={id}
                        folder="imagenes-talla-palos"
                      />
                    ) : (
                      <Form.Group>
                        <Form.Control
                          type="file"
                          placeholder="ZIP"
                          onChange={imagen1}
                        />
                      </Form.Group>
                    )}
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select
                        defaultValue="0"
                        {...register('primera_tendencia')}
                      >
                        <option value="">Seleccione</option>
                        <option value="Centro">Centro</option>
                        <option value="Tacon">Tacon</option>
                        <option value="Punta">Punta</option>
                        <option value="Centro punta">Centro punta</option>
                        <option value="Centro tacon">Centro tacon </option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('primera_seleccion_2')}
                      >
                        <option value="">Seleccione</option>
                        <option value="Straight">Straight</option>
                        <option value="Slice">Slice</option>
                        <option value="Hook">Hook</option>
                        <option value="Draw">Draw</option>
                        <option value="Fade">Fade</option>
                        <option value="Push">Push</option>
                        <option value="Pull">Pull</option>
                        <option value="Push slice">Push slice</option>
                        <option value="Pull Hook">Pull Hook</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  {imga1 !== "null" && imga1 !== undefined && imga1.length > 0 && (
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm mx-2 mt-2"
                        onClick={() => deleteID(idTallaPalos, 1, imga1)}
                      >
                        Eliminar
                      </button>
                    </td>
                  )}
                </tr>
                <tr>
                  <td className="textCenter">
                    <input
                      type="radio"
                      className="mt-3"
                      value="segunda"
                      name="ban"
                      {...register('principal')}
                      onClick={(e) => selColor(e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('segunda_color')}
                        onChange={(e) => setColorDos(e.target.value)}
                      >
                        <option value="0">Seleccione</option>
                        <option value="Gold">Gold</option>
                        <option value="Brown">Brown</option>
                        <option value="Orange">Orange</option>
                        <option value="Red">Red</option>
                        <option value="Black">Black</option>
                        <option value="Blue">Blue</option>
                        <option value="Green">Green</option>
                        <option value="White">White</option>
                        <option value="Silver">Silver</option>
                        <option value="Maroon">Maroon</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                  {imga2 !== null && imga2 !== undefined && imga2.length > 4 ? (
                      <Pdf
                        attach={2}
                        typeDoc={2}
                        doc={img2}
                        taxId={id}
                        folder="imagenes-talla-palos"
                      />
                    ) : (
                      <Form.Group id="zip">
                        <Form.Control
                          type="file"
                          placeholder="ZIP"
                          onChange={imagen2}
                        />
                      </Form.Group>
                    )}
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('segunda_tendencia')}
                      >
                        <option value="">Seleccione</option>
                        <option value="Centro">Centro</option>
                        <option value="Tacon">Tacon</option>
                        <option value="Punta">Punta</option>
                        <option value="Centro punta">Centro punta</option>
                        <option value="Centro tacon">Centro tacon </option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('segunda_seleccion_2')}
                      >
                        <option value="">Seleccione</option>
                        <option value="Straight">Straight</option>
                        <option value="Slice">Slice</option>
                        <option value="Hook">Hook</option>
                        <option value="Draw">Draw</option>
                        <option value="Fade">Fade</option>
                        <option value="Push">Push</option>
                        <option value="Pull">Pull</option>
                        <option value="Push slice">Push slice</option>
                        <option value="Pull Hook">Pull Hook</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  {imga2 !== "null" && imga2 !== undefined && imga2.length > 0 && (
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm mx-2 mt-2"
                        onClick={() => deleteID(idTallaPalos, 2, img2)}
                      >
                        Eliminar
                      </button>
                    </td>
                  )}
                </tr>
                <tr>
                  <td className="textCenter">
                    <input
                      type="radio"
                      className="mt-3"
                      value="tercera"
                      name="ban"
                      {...register('principal')}
                      onClick={(e) => selColor(e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('tercera_color')}
                        onChange={(e) => setColorTres(e.target.value)}
                      >
                        <option value="0">Seleccione</option>
                        <option value="Gold">Gold</option>
                        <option value="Brown">Brown</option>
                        <option value="Orange">Orange</option>
                        <option value="Red">Red</option>
                        <option value="Black">Black</option>
                        <option value="Blue">Blue</option>
                        <option value="Green">Green</option>
                        <option value="White">White</option>
                        <option value="Silver">Silver</option>
                        <option value="Maroon">Maroon</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                  {imga3 !== null && imga3 !== undefined && imga3.length > 4 ? (
                      <Pdf
                        attach={3}
                        typeDoc={2}
                        doc={img3}
                        taxId={id}
                        folder="imagenes-talla-palos"
                      />
                    ) : (
                      <Form.Group id="zip">
                        <Form.Control
                          type="file"
                          placeholder="ZIP"
                          onChange={imagen3}
                        />
                      </Form.Group>
                    )}
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('tercera_tendencia')}
                      >
                        <option value="">Seleccione</option>
                        <option value="Centro">Centro</option>
                        <option value="Tacon">Tacon</option>
                        <option value="Punta">Punta</option>
                        <option value="Centro punta">Centro punta</option>
                        <option value="Centro tacon">Centro tacon </option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('tercera_seleccion_2')}
                      >
                        <option value="">Seleccione</option>
                        <option value="Straight">Straight</option>
                        <option value="Slice">Slice</option>
                        <option value="Hook">Hook</option>
                        <option value="Draw">Draw</option>
                        <option value="Fade">Fade</option>
                        <option value="Push">Push</option>
                        <option value="Pull">Pull</option>
                        <option value="Push slice">Push slice</option>
                        <option value="Pull Hook">Pull Hook</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  {imga3 !== "null" && imga3 !== undefined && imga3.length > 0 && (
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm mx-2 mt-2"
                        onClick={() => deleteID(idTallaPalos, 3, img3)}
                      >
                        Eliminar
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Código de color en ping</th>
                  <th scope="col">Lie Flat</th>
                  <th scope="col">Lie Estandar</th>
                  <th scope="col">Lie Up</th>
                  <th scope="col">Tamaño Varilla</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Group id="city">
                      <Form.Control
                        required
                        disabled
                        type="text"
                        placeholder="Color"
                        value={color}
                        {...register('color')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select defaultValue="0" {...register('lie_flat')} onChange={(e) => lieFlat(e.target.value)} disabled={lieFlatSel > 0 ? 'disabled' : ''}>
                        <option value="">Seleccione</option>
                        <option value="1°">1°</option>
                        <option value="2°">2°</option>
                        <option value="3°">3°</option>
                        <option value="4°">4°</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue=""
                        {...register('lie_estandar')}
                        onChange={(e) => lieEstandar(e.target.value)} disabled={lieEstandarSel > 0 ? 'disabled' : ''}
                      >
                        <option value="">Seleccione</option>
                        <option value="0">0</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select defaultValue="0" {...register('lie_up')} onChange={(e) => lieUp(e.target.value)} disabled={lieUpSel > 0 ? 'disabled' : ''}>
                        <option value="">Seleccione</option>
                        <option value="1°">1°</option>
                        <option value="2°">2°</option>
                        <option value="3°">3°</option>
                        <option value="4°">4°</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('id_long_vara')}
                      >
                        <ListName url="long-vara" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-3">
            {idTallaPalos ? (
              <Button variant="success" type="submit">
                Actualizar
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            )}
          </div>
        </Form>
      </Accordion.Body>
    </Accordion.Item>
  )
}
