import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const LoftHierros = () => {
  const [data, setData] = useState([]);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{' '}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{' '}
        </label>
      </div>
    ),
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/loft-hierros-get`);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteID = (id) => {
    Swal.fire({
      title: 'Estas seguro de eliminar este registro?',
      text: 'No podrás revertir esto.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/loft-hierros-del/${id}`)
          .then((res) => {
            Swal.fire({
              title: 'Eliminado!',
              icon: 'success',
              timer: 1000,
            });
            fetchData();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const { SearchBar } = Search;

  return (
    <>
      <ToolkitProvider
        data={data}
        keyField="id"
        columns={[
          {
            dataField: 'id',
            text: 'ID',
            sort: true,
          },
          {
            dataField: 'sett',
            text: 'Set',
            sort: true,
          },
          {
            dataField: 'loft',
            text: 'Loft',
            sort: true,
          },
          {
            dataField: 'name',
            text: 'Modelo Cabeza',
            sort: true,
          },
          {
            dataField: 'id',
            text: 'Action',
            formatter: (cellContent, row) => (
              <>
                <Link to={`/loft-hierros/loft-hierro-details/${row.id}`}>
                  <button type="button" className="btn btn-primary btn-sm">
                    Ver
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn btn-danger btn-sm mx-2"
                  onClick={() => deleteID(row.id)}
                >
                  Del
                </button>
              </>
            ),
          },
        ]}
        search
      >
        {(props) => (
          <div className="table_class py-4">
            <Link to="/loft-hierros/loft-hierro-new/">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                style={{ width: '100px', marginBottom: '30px' }}
              >
                Nuevo
              </button>
            </Link>
            <div
              id="datatable-basic_filter"
              className="dataTables_filter px-1 pb-4"
            >
              <label>
                Search:
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  {...props.searchProps}
                />
              </label>
            </div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default LoftHierros;