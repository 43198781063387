import React, { useState } from 'react'
import { Col, Accordion, Row } from '@themesberg/react-bootstrap'
import Clients from '../../../components/fitting-components/Clients-informe'
import axios from 'axios'
import debounce from 'lodash/debounce';

const ClientsNew = () => {

  const [clients, setClients] = useState([])
  const [client, setClient] = useState(0)
  const [idClient, setIdClient] = useState(0)
  const handleChange = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/clients`)
      .then((response) => {
        setClients(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleClick = (name) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/client-name`,{
      params: {name}
    })
    .then((response) => {
      setIdClient(response.data[0].id)
    })
    .catch((err) => {
      console.log(err)
    })
  }
  
  return (
    <article className="table_class">
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-md-0">
          <h1 className="h2">Informe técnico</h1>
          <p className="mb-0">Descrpción de infome técnico {idClient}</p>
        </Col>
      </Row>
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-md-0">
          <Accordion className="" defaultActiveKey="1">
            <input
              id="username"
              type="text"
              placeholder='Nombre del cliente existente'
              className="form-control"
              list="clients"
              onChange={debounce(handleChange, 300)}
              onInput={e => setClient(e.target.value)}
            />
            <datalist id="clients">
              {clients.map((user) => (
                <option class="list" value={user.name} key={user.id} >
                  {user.name}
                </option>
              ))}
            </datalist>
            <br />
            <button
              className="btn btn-primary mb-5"
              onClick={() => handleClick(client)}
            >
              Seleccionar
            </button>
            <Clients idCli={idClient}/>
          </Accordion>
        </Col>
      </Row>
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-md-0"></Col>
      </Row>
    </article>
  )
}

export default ClientsNew
