import React, { useState, useEffect } from "react"; 
import axios from "axios";
import Select from 'react-select';

const ListValor = props => {
    const url = props.url
    const [item, setItem] = useState([])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/${url}`)
        .then((response) => {
            setItem(response.data)
        })
        .catch((err) => {
        console.log(err);
        });
    },[])

    return (
        <> 
            {
                item.map(element=>(
                    <option value={element.id}>{element.valor}</option>    
                ))
            }
        </>
    )
}

const ListName = props => {
    const url = props.url
    const [item, setItem] = useState([])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/${url}`)
        .then((response) => {
            setItem(response.data)
        })
        .catch((err) => {
        console.log(err);
        });
    },[])

    return (
        <>
            {
                item.map(element=>(
                    <option value={element.id}>{element.name}</option>    
                ))
            }
        </>
    )
}
export {ListValor, ListName};