import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const LoftDriver = () => {
  const [idLoftDriver, setIdLoftDriver] = useState(0);
  const [data, setData] = useState([]);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{' '}
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>{' '}
        </label>
      </div>
    ),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    modelos();
  }, []);

  const modelos = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/loft-drivers-get`)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoftDriver = (id) => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/loft-drivers-get-id`, {
        params: { id },
      })
      .then((response) => {
        if (response.data !== 'vacio') {
          setIdLoftDriver(response.data[0].id);
          defaultValues.id_loft_drivers = response.data[0].id
          defaultValues.name = response.data[0].name
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearFields = () => {
    reset({ id_loft_drivers: '', name: '' });
    setIdLoftDriver(0);
  };

  const onSubmit = (data) => {
    const endpoint = idLoftDriver
      ? `${process.env.REACT_APP_BASE_URL}/upd-loft-drivers`
      : `${process.env.REACT_APP_BASE_URL}/loft-drivers-ins`;

    axios
      .request({
        url: endpoint,
        method: idLoftDriver ? 'PUT' : 'POST',
        data: data,
      })
      .then((res) => {
        Swal.fire({
          html: '<i>Process complete!</i>',
          icon: 'success',
          timer: 1000,
        });
        clearFields();
        modelos();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteID = (id) => {
    Swal.fire({
      title: 'Estas seguro de eliminar este registro?',
      text: 'No podrás revertir esto.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/loft-drivers-del/${id}`)
          .then((res) => {
            Swal.fire({
              title: 'Eliminado!',
              icon: 'success',
              timer: 1000,
            });
            modelos();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const { SearchBar } = Search;

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={[
          {
            dataField: 'name',
            text: 'Loft Driver',
            sort: true,
          },
          {
            dataField: 'id',
            text: 'Action',
            formatter: (cellContent, row) => (
              <>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => getLoftDriver(row.id)}
                >
                  Act
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-sm mx-2"
                  onClick={() => deleteID(row.id)}
                >
                  Del
                </button>
              </>
            ),
          },
        ]}
        search
      >
        {(props) => (
          <div className="table_class py-4">
            <div id="datatable-basic_filter" className="dataTables_filter px-1 pb-4">
              <label>
                Search:
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  {...props.searchProps}
                />
              </label>
            </div>
            <div className="row">
              <h5 className="my-4">Agregar Loft Driver</h5>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register('id_loft_drivers')} />
                <table className="table table_class_informe">
                  <thead>
                    <tr>
                      <th scope="col">Loft Driver</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="seleccion">
                      <td>
                        <Form.Group>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Loft Driver"
                            {...register('name')}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <button type="submit" className="btn btn-success btn-sm mt-2">
                          Guardar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            </div>
            {Array.isArray(data) && data.length > 0 ? (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            ) : (
              <p>No hay datos disponibles</p>
            )}
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default LoftDriver;