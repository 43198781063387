import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import BgImage from "../assets/img/illustrations/signin.svg";
import logo from "../assets/img/pages/logo.jpeg";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");
  const [loginSuccessful, setLoginSuccessful] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    axios.get(`${process.env.REACT_APP_BASE_URL}/login`, {
      params: { user: user, password: password }
    }).then((result) => {
      console.log("resultado: ", result);
      if (result.data.token) {
        localStorage.setItem('token', result.data.token);
        localStorage.setItem('tipoUsuario', result.data.tipoUsuario);
        localStorage.setItem('nombres', result.data.nombres);
        localStorage.setItem('id', result.data.id);
        setLoginSuccessful(true);
      } else {
        setLoginSuccessful(false);
      }
    }).catch(err => {
      console.log(err);
    });
  };

  if (loginSuccessful) {
    const tipoUsuario = localStorage.getItem('tipoUsuario');
    if (tipoUsuario === 'admin') {
      navigate('/home');
    } else {
      navigate('/client');
    }
    window.location.reload();
  }

  return (
    <> 
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <img src={logo} width="200px" alt="logo" />
                    <h3 className="mb-0">Sign in to our platform</h3>
                  </div>
                  <Form className="mt-4">
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Your Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control 
                          autoFocus 
                          onChange={(event) => setUser(event.target.value)} 
                          required 
                          type="email" 
                          placeholder="example@company.com" 
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Your Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control 
                            required 
                            onChange={(event) => setPassword(event.target.value)} 
                            type="password" 
                            placeholder="Password" 
                          />
                        </InputGroup>
                      </Form.Group>
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={handleLogin} className="w-100">
                      Sign in
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};
